import Cookies from "universal-cookie";
import EventDetailsApi from "../api/EventDetailsApi";

const cookies = new Cookies();

export default async function EventDetailsService({ event, navigate }) {
  try {
    const eventDetails = await EventDetailsApi({ event_id: event.id });

    const result = eventDetails.data.result.eventDetails;

    cookies.set("event_details", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    return result;
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_title", "Event Details Service", {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_description", result ?? message, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    navigate("/error");

    throw new Error(error);
  }
}
