import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { FaPenAlt } from "react-icons/fa";

import { title } from "../../utils/ConstantsUtil";
import CheckCookiesUtil from "../../utils/CheckCookiesUtil";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import EventService from "../../services/EventService";
import CheckVolunteerService from "../../services/CheckVolunteerService";
import RegistrationStatusService from "../../services/RegistrationStatusService";

import LoaderComponent from "../../components/LoaderComponent";
import ButtonComponent from "../../components/ButtonComponent";

import RegistrationStatusDescriptionComponent from "../../components/Registration/RegistrationStatusDescriptionComponent";
import RegistrationStatusMinistryComponent from "../../components/Registration/RegistrationStatusMinistryComponent";

import RegistrationStatus from "../../assets/images/registration/status.png";

const cookies = new Cookies();

export default function StatusPage({ translate, setIsLoading, account }) {
  const titlePage = translate("registration_status");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const [registrationStatus, setRegistrationStatus] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    const fetchData = async () => {
      try {
        await CheckVolunteerService({ account: account, navigate: navigate });

        const event = await EventService({
          account: account,
          navigate: navigate,
          checkEventClosed: false,
        });

        const registrationStatus = await RegistrationStatusService({
          account: account,
          event: event,
          navigate: navigate,
        });
        setRegistrationStatus(registrationStatus);
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();

    setIsLoading(false);
  }, [translate, setIsLoading, navigate, account]);

  if (!registrationStatus) {
    return <LoaderComponent translate={translate} />;
  }

  const registrationStatusDescription = RegistrationStatusDescriptionComponent(
    registrationStatus.status.id
  );

  return (
    <div className="text-center">
      <img
        draggable="false"
        className="w-100"
        src={RegistrationStatus}
        alt={titlePage + " - " + title}
      />

      <h2 className="fw-bold my-3">{translate("status_registration")}</h2>
      <h4 className={"text-" + registrationStatus.status.style}>
        {registrationStatus.status.name ?? translate("active")}
      </h4>

      <div className="mb-3">{registrationStatusDescription}</div>

      {registrationStatus.status.id !== 69 ? (
        <>
          <RegistrationStatusMinistryComponent
            translate={translate}
            data={registrationStatus.first}
            color={"primary"}
          />

          <RegistrationStatusMinistryComponent
            translate={translate}
            data={registrationStatus.second}
            color={"info"}
          />
        </>
      ) : null}

      {registrationStatus.status.id === 69 || registrationStatus.status.id === 3 ? (
        <ButtonComponent
          className="w-100 my-2"
          color="primary"
          sortBy="right"
          text={translate("re_register") + " " + translate("servolution")}
          icon={<FaPenAlt className="ms-2" />}
          link={"/"}
          onClick={() =>
            cookies.set("re_register", true, {
              expires: new Date(cookies.get("expired")),
              path: "/",
            })
          }
        />
      ) : null}
    </div>
  );
}
