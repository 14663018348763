import React from "react";
import { FormGroup, Input } from "reactstrap";
import LabelComponent from "./LabelComponent";

export default function InputTextareaComponent({
  number,
  name,
  label,
  value,
  onChange,
  maxLength = 100,
  required = true,
}) {
  return (
    <FormGroup>
      <LabelComponent
        number={number}
        name={name}
        label={label}
        required={required}
      />

      <Input
        className="border border-1 border-dark"
        type="textarea"
        id={name}
        name={name}
        maxLength={maxLength}
        value={value}
        required={required}
        onChange={onChange}
      />
    </FormGroup>
  );
}
