import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import StepUtil from "../utils/StepUtil";
import SaveRegistrationApi from "../api/SaveRegistrationApi";

const cookies = new Cookies();

export default async function SaveRegistrationService({
  event,
  data,
  translate,
  navigate,
}) {
  const event_id = event.id;

  if (!data.first_option_ministry || !data.first_option_ministry_schedule_id) {
    return Swal.fire({
      title: translate("your_session_is_expired"),
      text: translate("please_register_servolution_from_start"),
      icon: "warning",
      confirmButtonText: translate("start_register"),
      footer: translate("mawar_sharon_church"),
      allowOutsideClick: false,
    }).then(() => {
      window.scrollTo(0, 0);

      StepUtil("Home");

      return navigate("/");
    });
  }

  try {
    const registration = await SaveRegistrationApi({
      event_id,
      data,
    });

    const result = registration.data.result;

    cookies.set("registration", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "success") {
      StepUtil("Registration Status");

      return navigate("/registration/status");
    }
  } catch (error) {
    const result = error.response.data.result;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "double registration.") {
      return Swal.fire({
        title: translate("double_registration"),
        text: translate("you_have_already_registered"),
        icon: "warning",
        confirmButtonText: translate("check_registration_status"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      }).then(() => {
        window.scrollTo(0, 0);

        StepUtil("Registration Status");

        return navigate("/registration/status");
      });
    } else {
      const footerError =
        "<a href='https://wa.me/6282298089363?text=```" +
        error.response.config.data +
        "```' target='_blank'>" +
        translate("send_error_to_developer") +
        "</a>";

      const title =
        error.response.statusText !== ""
          ? error.response.statusText
          : error.response.data.error.message;

      const text =
        error.response.data.result ?? error.response.data.error.message;

      return Swal.fire({
        title: error.response.status + " - " + title,
        text: error.message + " - " + text,
        icon: "error",
        confirmButtonText: translate("please_try_again"),
        footer: footerError,
        allowOutsideClick: false,
      });
    }
  }
}
