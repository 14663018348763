import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { Button } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";

import { title } from "../utils/ConstantsUtil";
import useDocumentTitle from "../hooks/useDocumentTitle";

import Error from "../assets/images/error.png";

const cookies = new Cookies();

export default function ErrorPage({ translate, setIsLoading }) {
  const titlePage = translate("error");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const errorTitle = cookies.get("error_title");
  const errorDescription = cookies.get("error_description");

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, [setIsLoading]);

  return (
    <>
      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={Error}
          alt={titlePage + " - " + title}
        />

        <h2 className="fw-bold">{translate("error")}</h2>
        <h4>
          {errorTitle ? translate(errorTitle) : translate("no_data_available")}
        </h4>
        <p>
          {errorDescription ? (
            translate(JSON.stringify(errorDescription))
          ) : (
            <>
              {translate("sorry_the_service_is_not_available")}
              <br />
              {translate("please_check_back_later")}
            </>
          )}
        </p>

        <Button color="dark" className="my-2" onClick={() => navigate(-1)}>
          <FaArrowLeft className="me-2" />
          {translate("back_to_previous_page")}
        </Button>
      </div>
    </>
  );
}
