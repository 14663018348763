import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import RedirectAuthUtil from "../utils/RedirectAuthUtil";

const cookies = new Cookies();

export default function CookiesSetService() {
  const code = new URLSearchParams(window.location.search).get("code");

  if (code) {
    const jwt = jwt_decode(code);
    const date = new Date(0);
    date.setUTCSeconds(jwt.exp);

    cookies.set("code", code, { expires: date, path: "/" });
    cookies.set("jwt", jwt, { expires: date, path: "/" });
    cookies.set("expired", date, { expires: date, path: "/" });

    window.history.replaceState(null, null, window.location.pathname);

    return code;
  }

  if (cookies.get("code")) {
    return cookies.get("code");
  }

  return RedirectAuthUtil();
}
