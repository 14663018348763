import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Route from "./routes/route";

import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import LoaderComponent from "./components/LoaderComponent";

import CookiesSetService from "./services/CookiesSetService";
import AccountService from "./services/AccountService";

const cookies = new Cookies();

export default function App() {
  require(cookies.get("i18nextLng`") === "en"
    ? "moment/locale/en-gb"
    : "moment/locale/id");

  const [translate] = useTranslation("language");

  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState(null);

  let checkAuth = true;

  if (window.location.pathname === "/paw") {
    checkAuth = false;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (checkAuth) {
        const code = await CookiesSetService();
        const account = await AccountService({ code: code });
        setAccount(account);
      } else {
        setAccount(null);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [checkAuth]);

  if (checkAuth) {
    if (isLoading) {
      return <LoaderComponent translate={translate} />;
    }

    if (!account && window.location.pathname !== "/join-connect-group") {
      return <LoaderComponent translate={translate} />;
    }
  }

  localStorage.clear();

  return (
    <Suspense fallback={<LoaderComponent translate={translate} />}>
      <Router>
        <HeaderComponent translate={translate} />
        <Container className="py-4">
          <Row className="justify-content-center">
            <Col xs="12" sm="10" md="8" lg="6" xl="4">
              <Route
                translate={translate}
                setIsLoading={setIsLoading}
                account={account}
              />
            </Col>
          </Row>
        </Container>
        <FooterComponent translate={translate} />
      </Router>
    </Suspense>
  );
}
