import React from "react";
import { FaArrowUp } from "react-icons/fa";
import { Button } from "reactstrap";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "auto",
  });
};

export default function BackToTopComponent({ translate }) {
  return (
    <div className="text-center mb-4">
      <Button
        color="link"
        className="text-dark text-decoration-none"
        onClick={scrollToTop}
      >
        <FaArrowUp />
        <div>{translate("back_to_top")}</div>
      </Button>
    </div>
  );
}
