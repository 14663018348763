import React, { useEffect } from "react";

import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import { title } from "../utils/ConstantsUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import EventClosed from "../assets/images/event-closed.png";

export default function EventClosedPage({ translate, setIsLoading }) {
  const titlePage = translate("event_closed");
  useDocumentTitle(titlePage);

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [setIsLoading]);

  return (
    <div className="text-center">
      <img
        draggable="false"
        className="w-100"
        src={EventClosed}
        alt={titlePage + " - " + title}
      />

      <h2 className="fw-bold my-3">{translate("servolution_is_inactive")}</h2>
      <h4>
        {translate("sorry")},{" "}
        {translate("servolution_registration_is_not_open_yet_or_has_expired")}
      </h4>
      <p>
        {translate(
          "the_next_servolution_will_be_announced_at_the_church_and_follow_our_social_media_for_more_information"
        )}
      </p>
    </div>
  );
}
