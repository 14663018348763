import React from "react";

export default function ShallomComponent({ translate, account }) {
  return (
    <div className="text-center">
      <h6 className="fw-bold">
        <span>{translate("shallom")},&nbsp;</span>
        <span className="text-capitalize">
          {translate(account.account.gender)}{" "}
          {account.account.name.toLowerCase()}
        </span>
      </h6>
      <div className="text-muted">
        {account.account.church.name} - {account.account.nij}
      </div>
    </div>
  );
}
