import React, { useEffect } from "react";

import { title } from "../utils/ConstantsUtil";
import useDocumentTitle from "../hooks/useDocumentTitle";

import ThankYou from "../assets/images/thank-you.png";

export default function PawThankYouPage({ translate, setIsLoading }) {
  const titlePage = translate("PAW Thank You");
  useDocumentTitle(titlePage);

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, [setIsLoading]);

  return (
    <>
      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={ThankYou}
          alt={titlePage + " - " + title}
        />

        <h2 className="fw-bold mt-5">{translate("thank_you")}</h2>
        <p>Terima kasih sudah mengisi pertanyaan ini.</p>
      </div>
    </>
  );
}
