import Cookies from "universal-cookie";
import GetPawApi from "../api/GetPawApi";

const cookies = new Cookies();

export default async function GetPawService({ registrant_id, navigate }) {
  try {
    const getPaw = await GetPawApi({ registrant_id });

    const result = getPaw.data.result;

    cookies.set("paw", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    return result;
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    if (result === 'This participant already updated the value.') {
      return navigate("/paw-thank-you");
    }

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("paw", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_title", "Get Paw Service", {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_description", result ?? message, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    navigate("/error");

    throw new Error(error);
  }
}
