import React from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
// import { file_url } from "../../utils/ConstantsUtil";
import { FaDownload, FaTrash } from "react-icons/fa";
import ButtonComponent from "../ButtonComponent";

export default function ViewComponent({
  translate,
  type,
  firstMinistryName,
  firstDivisionTypeId,
  firstDivisionName,
  firstDivisionDescription,
  firstScheduleAuditionRoom,
  firstScheduleAuditionSchedule,
  firstScheduleInterviewRoom,
  firstScheduleInterviewSchedule,
  // firstScheduleAttachments,
  secondMinistryName,
  secondDivisionTypeId,
  secondDivisionName,
  secondDivisionDescription,
  secondScheduleAuditionRoom,
  secondScheduleAuditionSchedule,
  secondScheduleInterviewRoom,
  secondScheduleInterviewSchedule,
  // secondScheduleAttachments,
  onClick,
}) {
  return (
    <>
      <Alert color="light" className="text-center" fade={false}>
        {translate("the_ministry_your_choose")}
      </Alert>

      <Row className="mb-2">
        <Col xs={6}>
          <Button
            className="w-100"
            color={
              parseInt(firstDivisionTypeId) === type.Audition
                ? "primary"
                : "info"
            }
            id="first"
          >
            {firstDivisionName}
          </Button>
        </Col>

        {secondScheduleAuditionRoom ? (
          <Col xs={6}>
            <Button
              className="w-100"
              color={
                parseInt(secondDivisionTypeId) === type.Audition
                  ? "primary"
                  : "info"
              }
              id="second"
            >
              {secondDivisionName}
            </Button>
          </Col>
        ) : null}
      </Row>

      <UncontrolledCollapse toggler="#first" className="mt-3 mb-2">
        <Card
          outline
          color={
            parseInt(firstDivisionTypeId) === type.Audition ? "primary" : "info"
          }
        >
          <CardBody>
            <CardTitle tag="h6" className="fw-bold text-center">
              {firstMinistryName}
            </CardTitle>

            <CardSubtitle className="mb-2 text-muted text-center" tag="h6">
              {firstDivisionName}
            </CardSubtitle>

            <CardText
              className="text-start"
              dangerouslySetInnerHTML={{
                __html: firstDivisionDescription,
              }}
            ></CardText>

            {/* {firstScheduleAttachments ? (
              <>
                <hr />
                <CardText>
                  <span className="fw-bold">{translate("attachments")} :</span>
                  <a
                    draggable="false"
                    href={file_url + "/materials/" + firstScheduleAttachments}
                    download={true}
                    className="btn btn-danger btn-sm ms-2"
                  >
                    <FaDownload className="me-2" />
                    {translate("download")} {translate("attachment")}
                  </a>
                </CardText>
              </>
            ) : null} */}

            <hr />

            {parseInt(firstDivisionTypeId) === type.Audition ? (
              <>
                <CardText>
                  <span className="fw-bold">
                    {translate("audition_schedule")}
                  </span>
                  <br />
                  {firstScheduleAuditionSchedule}
                </CardText>
                <CardText>
                  <span className="fw-bold">{translate("audition_room")}</span>
                  <br />
                  {firstScheduleAuditionRoom}
                </CardText>
              </>
            ) : null}

            <CardText>
              <span className="fw-bold">{translate("interview_schedule")}</span>
              <br />
              {firstScheduleInterviewSchedule}
            </CardText>
            <CardText>
              <span className="fw-bold">{translate("interview_room")}</span>
              <br />
              {firstScheduleInterviewRoom}
            </CardText>
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      {secondScheduleAuditionRoom ? (
        <UncontrolledCollapse toggler="#second" className="mt-3 mb-2">
          <Card
            outline
            color={
              parseInt(secondDivisionTypeId) === type.Audition
                ? "primary"
                : "info"
            }
          >
            <CardBody>
              <CardTitle tag="h6" className="fw-bold text-center">
                {secondMinistryName}
              </CardTitle>

              <CardSubtitle className="mb-2 text-muted text-center" tag="h6">
                {secondDivisionName}
              </CardSubtitle>

              <CardText
                className="text-start"
                dangerouslySetInnerHTML={{
                  __html: secondDivisionDescription,
                }}
              ></CardText>

              {/* {secondScheduleAttachments ? (
                <CardText>
                  <span className="fw-bold">{translate("attachments")} :</span>
                  <a
                    draggable="false"
                    href={file_url + "/materials/" + secondScheduleAttachments}
                    download={true}
                    className="btn btn-danger btn-sm ms-2"
                  >
                    <FaDownload className="me-2" />
                    {translate("download")} {translate("attachment")}
                  </a>
                </CardText>
              ) : null} */}

              {parseInt(secondDivisionTypeId) === type.Audition ? (
                <>
                  <CardText>
                    <span className="fw-bold">
                      {translate("audition_schedule")}
                    </span>
                    <br />
                    {secondScheduleAuditionSchedule}
                  </CardText>
                  <CardText>
                    <span className="fw-bold">
                      {translate("audition_room")}
                    </span>
                    <br />
                    {secondScheduleAuditionRoom}
                  </CardText>
                </>
              ) : null}

              {parseInt(secondDivisionTypeId) === type.Interview ? (
                <>
                  <CardText>
                    <span className="fw-bold">
                      {translate("interview_schedule")}
                    </span>
                    <br />
                    {secondScheduleInterviewSchedule}
                  </CardText>
                  <CardText>
                    <span className="fw-bold">
                      {translate("interview_room")}
                    </span>
                    <br />
                    {secondScheduleInterviewRoom}
                  </CardText>
                </>
              ) : null}
            </CardBody>
          </Card>
        </UncontrolledCollapse>
      ) : null}

      <ButtonComponent
        className="w-100 my-2"
        color="danger"
        sortBy="right"
        text={translate("remove") + " " + translate("ministry")}
        icon={<FaTrash className="ms-2" />}
        onClick={onClick}
      />
    </>
  );
}
