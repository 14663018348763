import React from "react";
import { FormGroup, Input, FormText, FormFeedback } from "reactstrap";
import LabelComponent from "./LabelComponent";

export default function InputFileComponent({
  number,
  name,
  label,
  description,
  image,
  feedback,
  accept,
  upload,
  required = true,
}) {
  return (
    <FormGroup>
      <LabelComponent
        number={number}
        name={name}
        label={label}
        required={required}
      />

      <Input
        className="border border-1 border-dark"
        invalid={feedback ? true : false}
        valid={image ? (feedback ? false : true) : null}
        id={name}
        name={name}
        type="file"
        accept={accept}
        required={required ? (image ? false : true) : true}
        onChange={upload}
      />
      <FormText>{description}</FormText>
      <FormFeedback>{feedback}</FormFeedback>
    </FormGroup>
  );
}
