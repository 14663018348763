import React from "react";
import moment from "moment";
import { file_url } from "./../../utils/ConstantsUtil";
import { FaDownload } from "react-icons/fa";

export default function RegistrationStatusMinistryScheduleComponent({
  translate,
  schedule,
  type,
}) {
  return (
    <div>
      <div className="text-start mt-4">
        <h6 className="fw-bold">{translate("description")} :</h6>
        <p
          dangerouslySetInnerHTML={{
            __html: schedule.description,
          }}
        ></p>

        {schedule.attachment && schedule.attachment !== "-" ? (
          <>
            <span className="fw-bold">{translate("attachments")} :</span>
            <a
              draggable="false"
              href={file_url + "/materials/" + schedule.attachment}
              download={true}
              className="btn btn-danger btn-sm ms-2"
            >
              <FaDownload className="me-2" />
              {translate("download")} {translate("attachment")}
            </a>
          </>
        ) : null}
      </div>

      {type === 1 ? (
        <div className="text-start mt-4">
          <h6 className="fw-bold">{translate("audition_schedule")} :</h6>
          <p>
            {schedule.audition_schedule && schedule.audition_schedule !== "-"
              ? moment(schedule.audition_schedule).format("LLLL")
              : translate("will_be_contacted_by_pic")}
          </p>

          <h6 className="fw-bold">{translate("audition_room")} :</h6>
          <p>
            {schedule.audition_room && schedule.audition_room !== "-"
              ? schedule.audition_room
              : translate("will_be_contacted_by_pic")}
          </p>

          <h6 className="fw-bold">{translate("interview_schedule")} :</h6>
          <p>
            {schedule.interview_schedule && schedule.interview_schedule !== "-"
              ? moment(schedule.interview_schedule).format("LLLL")
              : translate("will_be_contacted_by_pic")}
          </p>

          <h6 className="fw-bold">{translate("interview_room")} :</h6>
          <p>
            {schedule.interview_room && schedule.interview_room !== "-"
              ? schedule.interview_room
              : translate("will_be_contacted_by_pic")}
          </p>
        </div>
      ) : null}

      {type === 2 ? (
        <div className="text-start mt-4">
          <h6 className="fw-bold">{translate("interview_schedule")} :</h6>
          <p>
            {schedule.interview_schedule && schedule.interview_schedule !== "-"
              ? moment(schedule.interview_schedule).format("LLLL")
              : translate("will_be_contacted_by_pic")}
          </p>

          <h6 className="fw-bold">{translate("interview_room")} :</h6>
          <p>
            {schedule.interview_room && schedule.interview_room !== "-"
              ? schedule.interview_room
              : translate("will_be_contacted_by_pic")}
          </p>
        </div>
      ) : null}
    </div>
  );
}
