import ImageNotAvailable from "../assets/images/image-not-available.png";
import User from "../assets/images/user.png";

export const handleImageError = (event) => {
  event.target.src = ImageNotAvailable;
};

export const handleUserImageError = (event) => {
  event.target.src = User;
};
