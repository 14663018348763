import React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "../pages/HomePage";
import ProfilePage from "../pages/ProfilePage";
import WaterBaptismPage from "../pages/WaterBaptismPage";
import MinistryPage from "../pages/MinistryPage";
import QuestionPage from "../pages/QuestionPage";
import ThankYouPage from "../pages/ThankYouPage";

import StatusPage from "../pages/Registration/StatusPage";
import NotExistsPage from "../pages/Registration/NotExistsPage";
import ApprovedPage from "../pages/Registration/ApprovedPage";
import RejectedPage from "../pages/Registration/RejectedPage";
import ClosedPage from "../pages/Registration/ClosedPage";
import CompletePage from "../pages/Registration/CompletePage";
import NotEligiblePage from "../pages/Registration/NotEligiblePage";
import ApprovalPage from "../pages/Registration/ApprovalPage";

import HolySpiritBaptizedPage from "../pages/HolySpiritBaptizedPage";
import EventClosedPage from "../pages/EventClosedPage";
import VolunteerActivePage from "../pages/VolunteerActivePage";
import JoinConnectGroupPage from "../pages/JoinConnectGroupPage";

import DeveloperPage from "../pages/DeveloperPage";
import ErrorPage from "../pages/ErrorPage";
import Error404Page from "../pages/Error404Page";

import LogoutService from "../services/LogoutService";
import PawPage from "../pages/PawPage";
import PawThankYouPage from "../pages/PawThankYouPage";

const router = ({ translate, setIsLoading, account }) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <HomePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/profile"
        element={
          <ProfilePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/water-baptism"
        element={
          <WaterBaptismPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/ministry"
        element={
          <MinistryPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/question"
        element={
          <QuestionPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/thank-you"
        element={
          <ThankYouPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/registration/status"
        element={
          <StatusPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        path="/registration/not-exists"
        element={
          <NotExistsPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        path="/registration/approved"
        element={
          <ApprovedPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        path="/registration/rejected"
        element={
          <RejectedPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        path="/registration/closed"
        element={
          <ClosedPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        path="/registration/complete"
        element={
          <CompletePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        path="/registration/not-eligible"
        element={
          <NotEligiblePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        path="/registration/approval"
        element={
          <ApprovalPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/holy-spirit-baptized"
        element={
          <HolySpiritBaptizedPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/event-closed"
        element={
          <EventClosedPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/volunteer-active"
        element={
          <VolunteerActivePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/join-connect-group"
        element={
          <JoinConnectGroupPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/paw"
        element={
          <PawPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/paw-thank-you"
        element={
          <PawThankYouPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/developer"
        element={
          <DeveloperPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/logout"
        element={
          <LogoutService
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="/error"
        element={
          <ErrorPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />

      <Route
        exact
        path="*"
        element={
          <Error404Page
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
          />
        }
      />
    </Routes>
  );
};

export default router;
