import moment from "moment";
import Cookies from "universal-cookie";

import RegistrationApprovalDetailsApi from "../api/RegistrationApprovalDetailsApi";

const cookies = new Cookies();

export default async function RegistrationApprovalDetailsService({
  event_id,
  person_id,
  leader_id,
  account,
  navigate,
}) {
  try {
    const registrationApproval = await RegistrationApprovalDetailsApi({
      event_id: event_id,
      person_id: person_id,
      leader_id: leader_id,
    });

    const data = registrationApproval.data;

    cookies.set("registration_approval", data, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    const today = moment().format("YYYY-MM-DD");
    const maximumApprovalDate = moment(data.event.maximumApprovalDate).format(
      "YYYY-MM-DD"
    );

    if (account.account.mygms_id !== leader_id) {
      // return navigate("/registration/not-eligible");
    }

    if (today > maximumApprovalDate) {
      return navigate("/registration/closed");
    }

    return data;
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "This registrant has been approved") {
      return navigate("/registration/approved");
    }

    if (result === "This registrant has been rejected") {
      return navigate("/registration/rejected");
    }

    if (result === "This registrant is not exist") {
      return navigate("/registration/not-exists");
    }

    cookies.set("error_title", "Registration Approval Details", {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_description", result ?? message, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    navigate("/error");

    throw new Error(error);
  }
}
