import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function StepUtil(step) {
  window.scrollTo(0, 0);
  cookies.set("step", step, {
    expires: new Date(cookies.get("expired")),
    path: "/",
  });
}
