import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function SaveRegistrationApi({ event_id, data }) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return await axios.post(
    api_url + "/registration?token=" + token + "&event_id=" + event_id,
    data,
    {
      headers,
    }
  );
}
