import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function SaveApprovalApi({
  event_id,
  person_id,
  leader_id,
  registration_id,
  data,
}) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return await axios.patch(
    api_url +
      "/registration/approval?token=" +
      token +
      "&event_id=" +
      event_id +
      "&person_id=" +
      person_id +
      "&leader_id=" +
      leader_id +
      "&registration_id=" +
      registration_id,
    data,
    {
      headers,
    }
  );
}
