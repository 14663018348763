import StepUtil from "./StepUtil";

export default function NavigateUtil({ navigate, step }) {
  if (step === "Profile") {
    return navigate("/profile");
  }

  if (step === "Water Baptism") {
    return navigate("/water-baptism");
  }

  if (step === "Ministry") {
    return navigate("/ministry");
  }

  if (step === "Question") {
    return navigate("/question");
  }

  if (step === "Thank You") {
    return navigate("/thank-you");
  }

  if (step === "Registration Status") {
    return navigate("/registration/status");
  }

  return StepUtil("Home");
}
