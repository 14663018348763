import Cookies from "universal-cookie";
import StepUtil from "../utils/StepUtil";
import SaveRegistrationFailApi from "../api/SaveRegistrationFailApi";

const cookies = new Cookies();

export default async function SaveRegistrationFailService({
  event,
  data,
  navigate,
}) {
  const event_id = event.id;

  try {
    const registration = await SaveRegistrationFailApi({
      event_id,
      data,
    });

    const result = registration.data.result;

    cookies.set("registration_fail", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "success") {
      StepUtil("Holy Spirit Baptized");

      return navigate("/holy-spirit-baptized");
    }
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_title", "Save Registration Fail", {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_description", result ?? message, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    navigate("/error");

    throw new Error(error);
  }
}
