import React from "react";
import { Label } from "reactstrap";
import RequiredComponent from "./RequiredComponent";

export default function LabelComponent({
  number,
  name,
  label,
  required = true,
}) {
  return (
    <Label for={name}>
      {number ? number + ". " + label : label}
      {required ? <RequiredComponent /> : null}
    </Label>
  );
}
