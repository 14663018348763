import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
} from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";

import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import StepUtil from "../utils/StepUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import CheckRegistrationStatusService from "../services/CheckRegistrationStatusService";
import CheckVolunteerService from "../services/CheckVolunteerService";
import EventService from "../services/EventService";

import ButtonComponent from "../components/ButtonComponent";
import FormComponent from "../components/WaterBaptism/FormComponent";

const cookies = new Cookies();

export default function WaterBaptismPage({ translate, setIsLoading, account }) {
  const titlePage = translate("water_baptism");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    if (cookies.get("step") !== "Water Baptism") {
      return navigate("/profile");
    }

    const fetchData = async () => {
      try {
        await CheckVolunteerService({ account: account, navigate: navigate });

        const event = await EventService({
          account: account,
          navigate: navigate,
        });

        await CheckRegistrationStatusService({
          account: account,
          event: event,
          navigate: navigate,
        });
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();

    setIsLoading(false);
  }, [translate, setIsLoading, navigate, account]);

  return (
    <>
      <Card color="info" outline>
        <CardHeader className="bg-info text-white text-center">
          {translate("form")} {translate("water_baptism")}
        </CardHeader>

        <CardBody>
          <CardTitle tag="h6" className="fw-bold">
            {translate(
              "please_complete_a_form_water_baptism_to_be_eligible_for_servolution"
            )}
          </CardTitle>

          <CardText>
            {translate(
              "this_baptism_data_will_go_through_a_verification_process_before_proceeding_to_the_leaders_approval_process_make_sure_you_fill_in_the_data_correctly"
            )}
          </CardText>

          <FormComponent translate={translate} />
        </CardBody>

        <CardFooter className="text-center">
          {translate("form")} {translate("water_baptism")}
        </CardFooter>
      </Card>

      <ButtonComponent
        link={"/profile"}
        color={"light"}
        text={translate("back_to") + " " + translate("profile")}
        icon={<FaArrowLeft className="me-2" />}
        sortBy={"right"}
        onClick={() => StepUtil("Profile")}
      />
    </>
  );
}
