import React, { useEffect } from "react";

import Cookies from "universal-cookie";

import CheckCookiesUtil from "../../utils/CheckCookiesUtil";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import RegistrationComponent from "../../components/Registration/RegistrationComponent";
import LoaderComponent from "../../components/LoaderComponent";

import RegistrationNotEligible from "../../assets/images/registration/not-eligible.png";

const cookies = new Cookies();

export default function NotEligiblePage({ translate, setIsLoading, account }) {
  const titlePage = translate("registration_approval_not_eligible");
  useDocumentTitle(titlePage);

  cookies.remove("referrer_url", { path: "/" });

  useEffect(() => {
    cookies.remove("referrer_url", { path: "/" });
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [translate, setIsLoading]);

  if (!account) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <RegistrationComponent
      titlePage={titlePage}
      name={translate("registration_approval_not_eligible")}
      description={translate(
        "you_are_not_allowed_to_access_this_registration_approval"
      )}
      image={RegistrationNotEligible}
    />
  );
}
