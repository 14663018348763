import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function RegistrationAttachmentApi({ formData }) {
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };

  return await axios.post(
    api_url + "/registration/attachment?token=" + token,
    formData,
    {
      headers,
    }
  );
}
