import Cookies from "universal-cookie";
import AccountDetailsApi from "../api/AccountDetailsApi";
import RedirectAuthUtil from "../utils/RedirectAuthUtil";

const cookies = new Cookies();

export default async function AccountService({ code }) {
  try {
    const account = await AccountDetailsApi({ code: code });

    cookies.set("account", account.data.result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    return account.data.result;
  } catch (error) {
    const result = error.response.data.result;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "CG inactive.") {
      if (window.location.pathname !== "/join-connect-group") {
        return (window.location.href = "/join-connect-group");
      }
    }

    if (result === "Invalid Token!") {
      return RedirectAuthUtil();
    }
  }
}
