import moment from "moment";
import Cookies from "universal-cookie";
import EventActiveApi from "../api/EventActiveApi";

const cookies = new Cookies();

export default async function EventCheckChurchService({ church_id }) {
  try {
    const event = await EventActiveApi({ church_id: church_id });

    const result = event.data.result[0];

    cookies.set("event", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    const today = moment();

    const open_date = result.openDate;
    // const open_date = today; // TESTING

    const close_date = result.closeDate;
    // const close_date = today; // TESTING

    const openDate = moment(open_date);
    const closeDate = moment(close_date).add(1, 'd');

    if (today.isBefore(openDate) || today.isAfter(closeDate)) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}
