export default function getRegistationStatusDescription(statusId) {
  if (statusId === 1) {
    return "Pendaftaran Baru";
  } else if (statusId === 2) {
    return "Pendaftaran Pengguna Disetujui Oleh Pemimpin";
  } else if (statusId === 3) {
    return "Pendaftaran Pengguna Ditolak Oleh Pemimpin";
  } else if (statusId === 4) {
    return "Lulus Audisi";
  } else if (statusId === 5) {
    return "Gagal Audisi";
  } else if (statusId === 6) {
    return "Gagal Audisi, Dan Lanjutkan Ke Opsi Kedua";
  } else if (statusId === 7) {
    return "Lulus Wawancara";
  } else if (statusId === 8) {
    return "Gagal Dalam Wawancara";
  } else if (statusId === 9) {
    return "Resmi Menjadi Relawan";
  } else if (statusId === 12) {
    return "Pengecekan Data Baptis";
  } else if (statusId === 13) {
    return "Validasi Baptisan Gagal";
  } else if (statusId === 14) {
    return "Validasi Baptisan Berhasil";
  } else if (statusId === 69) {
    return "Jika Anda sudah menerima Baptisan Roh Kudus, Anda dapat melakukan Daftar Ulang Servolution";
  } else {
    return "Maaf, Anda tidak bisa melakukan pendaftaran Servolution saat ini karena Anda masih aktif sebagai Volunteer.";
  }
}
