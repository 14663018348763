import React from "react";
import { FormGroup, Label } from "reactstrap";
import { title } from "../utils/ConstantsUtil";

export default function PreviewComponent({ translate, name, image }) {
  if (image) {
    return (
      <FormGroup>
        <Label for="preview">
          {translate("preview")} {translate(name)}
        </Label>
        <a draggable="false" href={image} target="_blank" rel="noreferrer">
          <img
            draggable="false"
            className="w-100 img-thumbnail"
            src={image}
            alt={translate(name) + " - " + title}
          />
        </a>
      </FormGroup>
    );
  }
}
