import React, { useState } from "react";

import Cookies from "universal-cookie";
import Swal from "sweetalert2";

import { Form } from "reactstrap";
import { FaArrowRight } from "react-icons/fa";

import {
  file_url,
  max_image_size,
  max_image_size_text,
  image_type,
  image_type_text,
  accept_image,
} from "../../utils/ConstantsUtil";
import StepUtil from "../../utils/StepUtil";

import RegistrationAttachmentService from "../../services/RegistrationAttachmentService";

import AgreeComponent from "../AgreeComponent";
import ButtonComponent from "../ButtonComponent";
import InputDateComponent from "../InputDateComponent";
import InputRadioComponent from "../InputRadioComponent";
import InputTextComponent from "../InputTextComponent";
import PreviewComponent from "../PreviewComponent";
import InputFileComponent from "../InputFileComponent";

const cookies = new Cookies();

export default function FormComponent({ translate }) {
  let number = 1;
  let disabled = true;

  const photoBefore = file_url + "/photos/" + cookies.get("certificate");

  const [certificate, setCertificate] = useState(
    cookies.get("certificate") ? photoBefore : null
  );

  const [certificateInvalid, setCertificateInvalid] = useState(null);

  const [baptismDate, setBaptismDate] = useState(cookies.get("baptism_date"));

  const [baptismType, setBaptismType] = useState(cookies.get("baptism_type"));

  const [baptismPlace, setBaptismPlace] = useState(
    cookies.get("baptism_place") ?? ""
  );

  const [birthPlace, setBirthPlace] = useState(
    cookies.get("birth_place") ?? ""
  );

  const [fatherName, setFatherName] = useState(
    cookies.get("father_name") ?? ""
  );

  const [motherName, setMotherName] = useState(
    cookies.get("mother_name") ?? ""
  );

  const [agree, setAgree] = useState(false);

  const onChangeBaptismDate = (e) => {
    const value = e.target.value;
    setBaptismDate(value);
    cookies.set("baptism_date", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeBaptismType = (e) => {
    const value = e.target.value;
    setBaptismType(value);
    cookies.set("baptism_type", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeBaptismPlace = (e) => {
    const value = e.target.value;
    setBaptismPlace(value);
    cookies.set("baptism_place", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeBirthPlace = (e) => {
    const value = e.target.value;
    setBirthPlace(value);
    cookies.set("birth_place", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeFatherName = (e) => {
    const value = e.target.value;
    setFatherName(value);
    cookies.set("father_name", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeMotherName = (e) => {
    const value = e.target.value;
    setMotherName(value);
    cookies.set("mother_name", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onClickAgree = (e) => {
    const checked = e.target.checked;
    setAgree(checked);
    cookies.set("agree", checked, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  if (
    certificate &&
    baptismDate &&
    baptismType &&
    baptismPlace &&
    fatherName &&
    motherName &&
    agree
  ) {
    disabled = false;
  }

  const upload = async (e) => {
    const image = e.target.files[0];

    if (image) {
      if (image.size > max_image_size) {
        const message =
          translate("image_size_more_than") + " " + max_image_size_text;

        cookies.set("error", message, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });

        Swal.fire({
          title: translate("warning"),
          text: message,
          icon: "warning",
          confirmButtonText: translate("please_try_again"),
          footer: translate("mawar_sharon_church"),
          allowOutsideClick: false,
        });

        setCertificateInvalid(message);

        return;
      }

      if (!image_type.includes(image.type)) {
        const message =
          translate("format_image_not_valid") +
          ". " +
          translate("only") +
          " " +
          image_type_text;

        cookies.set("error", message, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });

        Swal.fire({
          title: translate("warning"),
          text: message,
          icon: "warning",
          confirmButtonText: translate("please_try_again"),
          footer: translate("mawar_sharon_church"),
          allowOutsideClick: false,
        });

        setCertificateInvalid(message);

        return;
      }

      const formData = new FormData();
      formData.append("attachment", image);

      const certificate = await RegistrationAttachmentService({
        translate,
        formData,
      });

      if (certificate === null) {
        setCertificateInvalid(
          translate("upload") +
            " " +
            translate("certificate") +
            " " +
            translate("error")
        );
        return;
      }

      cookies.set("certificate", certificate, {
        expires: new Date(cookies.get("expired")),
        path: "/",
      });

      setCertificateInvalid(null);
      setCertificate(file_url + "/photos/" + certificate);
    }
  };

  return (
    <Form>
      <InputFileComponent
        translate={translate}
        name={"certificate"}
        label={translate("certificate")}
        description={
          translate("format") +
          " " +
          image_type_text +
          " " +
          translate("max_size") +
          " " +
          max_image_size_text
        }
        image={certificate}
        feedback={certificateInvalid}
        accept={accept_image}
        upload={upload}
      />

      <PreviewComponent
        translate={translate}
        name={"certificate"}
        image={certificate}
      />

      <InputDateComponent
        number={number++}
        name={"baptism_date"}
        label={translate("baptism_date")}
        value={baptismDate}
        onChange={onChangeBaptismDate}
      />

      <InputRadioComponent
        number={number++}
        name={"baptism_type"}
        label={translate("baptism_type")}
        value={baptismType}
        options={[
          {
            value: "Percik",
            description: translate("splash"),
          },
          {
            value: "Tuang (Siram)",
            description: translate("pour") + " (" + translate("flush") + ")",
          },
          {
            value: "Selam",
            description: translate("dive"),
          },
        ]}
        inline={false}
        onChange={onChangeBaptismType}
      />

      <InputTextComponent
        number={number++}
        name={"baptism_place"}
        label={translate("baptism_place")}
        value={baptismPlace}
        onChange={onChangeBaptismPlace}
      />

      <InputTextComponent
        number={number++}
        name={"birth_place"}
        label={translate("birth_place")}
        value={birthPlace}
        onChange={onChangeBirthPlace}
      />

      <InputTextComponent
        number={number++}
        name={"father_name"}
        label={translate("father_name")}
        value={fatherName}
        onChange={onChangeFatherName}
      />

      <InputTextComponent
        number={number++}
        name={"mother_name"}
        label={translate("mother_name")}
        value={motherName}
        onChange={onChangeMotherName}
      />

      <AgreeComponent
        text={translate(
          "i_hereby_complete_correctly_and_agree_with_the_rules_of_the_baptist_letter_to_the_mawar_sharon_church"
        )}
        onClick={onClickAgree}
      />

      <ButtonComponent
        disabled={disabled}
        link={"/ministry"}
        text={translate("save_and_continue")}
        icon={<FaArrowRight className="ms-2" />}
        onClick={() => StepUtil("Ministry")}
      />
    </Form>
  );
}
