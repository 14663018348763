import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function EventActiveApi({ church_id }) {
  return await axios.get(api_url + "/events", {
    params: {
      token: token,
      church_id: church_id,
    },
  });
}
