import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { title } from "../utils/ConstantsUtil";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import ConnectGroup from "../assets/images/connect-group.png";
import LoaderComponent from "../components/LoaderComponent";

export default function JoinConnectGroupPage({
  translate,
  setIsLoading,
  account,
}) {
  const titlePage = translate("connect_group");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    if (account) {
      return navigate("/");
    }

    setIsLoading(false);
  }, [account, navigate, setIsLoading]);

  if (account) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <>
      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={ConnectGroup}
          alt={titlePage + " - " + title}
        />

        <h3 className="fw-bold my-3">{translate("active_in_connect_group")}</h3>
        <h4>
          {translate("sorry")},{" "}
          {translate("you_must_join_cg_connect_group_first")}
        </h4>
        <p>
          {translate(
            "servolution_can_only_be_done_if_you_are_already_active_in_cg"
          )}
        </p>
      </div>
    </>
  );
}
