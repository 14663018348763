import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function EventDetailsApi({ event_id }) {
  return await axios.get(api_url + "/event/details", {
    params: {
      token: token,
      event_id: event_id,
    },
  });
}
