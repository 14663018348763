import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

export default function TodayComponent({ translate }) {
  const [today, setToday] = useState(null);

  useEffect(() => {
    setInterval(() => {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        todayZone: "Asia/Jakarta",
      };
      const date = new Date().toLocaleString("id", options);
      const time = new Date().toLocaleTimeString("id");
      let dateTime = time + " | " + date;
      setToday(dateTime);
    }, 1000);
  }, []);

  return (
    <Card className="mb-3">
      <CardBody>
        <h4>{translate("today")}</h4>
        <h6>{today}</h6>
      </CardBody>
    </Card>
  );
}
