import React, { useState } from "react";

import Cookies from "universal-cookie";

import AsyncSelect from "react-select/async";
import {
  city_url,
  header_x_type_sense_api_key,
} from "../../utils/ConstantsUtil";

const cookies = new Cookies();

const CityOfDomicileComponent = ({ translate }) => {
  const [selectedOption, setSelectedOption] = useState(
    cookies.get("kota_dipilih")
  );

  const loadOptions = async (inputValue) => {
    if (inputValue.length < 4) {
      return [];
    }

    const cityUrl = city_url;
    const headerXTypeSenseApiKey = header_x_type_sense_api_key;

    const response = await fetch(
      `${cityUrl}/collections/cities/documents/search?q=${inputValue}&query_by=full_name`,
      {
        headers: {
          "x-typesense-api-key": headerXTypeSenseApiKey,
        },
      }
    );

    const data = await response.json();

    return data.hits.map((item) => ({
      value: item.document.id,
      label: item.document.full_name,
    }));
  };

  const handleChange = (option) => {
    cookies.set("kota_domisili", option.label);
    cookies.set("kota_dipilih", option);

    setSelectedOption(option);
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const customNoOptionsMessage = () => {
    const remainingChars = 4 - inputValue.length;
    if (remainingChars > 0) {
      return `Please enter ${remainingChars} more character${
        remainingChars > 1 ? "s" : ""
      }`;
    }
    return "No options";
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      value={selectedOption}
      placeholder={translate("search")}
      noOptionsMessage={customNoOptionsMessage}
    />
  );
};

export default CityOfDomicileComponent;
