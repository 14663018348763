import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export default function AgreeComponent({ text, onClick, required = true }) {
  return (
    <FormGroup check>
      <Label check>
        <Input
          className="border border-1 border-dark"
          type="checkbox"
          id="agree"
          name="agree"
          value={true}
          required={required}
          onClick={onClick}
        />{" "}
        {text}
      </Label>
    </FormGroup>
  );
}
