import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import useDocumentTitle from "../hooks/useDocumentTitle";

import LoaderComponent from "../components/LoaderComponent";
import { Card, Form } from "reactstrap";
import YoutubeComponent from "../components/Ministry/YoutubeComponent";
import InputRadioComponent from "../components/InputRadioComponent";
import GetPawService from "../services/GetPawService";
import PatchPawService from "../services/PatchPawService";
import SubmitComponent from "../components/SubmitComponent";
import { FaSave } from "react-icons/fa";

const cookies = new Cookies();

export default function PawPage({ translate, setIsLoading }) {
  const titlePage = translate("PAW");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const [getPaw, setGetPaw] = useState(null);

  const registrant_id = new URLSearchParams(window.location.search).get(
    "registrant_id"
  );

  const [wantPawKidz, setWantPawKidz] = useState(
    cookies.get("want_paw_kidz") ?? "0"
  );

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onChangeWantPawKidz = (e) => {
    const value = e.target.value;
    setWantPawKidz(value);
    cookies.set("want_paw_kidz", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setSubmitDisabled(true);

    await PatchPawService({
      registrant_id: registrant_id,
      want_paw_kidz: wantPawKidz,
      translate: translate,
      navigate: navigate,
    });

    setSubmitDisabled(false);

    window.location.reload();
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const getPaw = await GetPawService({
          registrant_id: registrant_id,
          navigate: navigate,
        });

        setGetPaw(getPaw);
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();

    setIsLoading(false);
  }, [registrant_id, navigate, setIsLoading]);

  if (!getPaw) {
    return <LoaderComponent translate={translate} />;
  }

  // if (getPaw.wantPawKidz !== null) {
  //   return (
  //     <>
  //       <Card body color="primary" outline>
  //         Kamu sudah mengisi jawaban ini.
  //       </Card>
  //     </>
  //   );
  // }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Card body color="primary" outline>
          <YoutubeComponent />

          <InputRadioComponent
            noMargin={true}
            number={""}
            name={"want_paw_kidz"}
            label={
              translate(
                "if_you_do_not_pass_the_praise_and_worship_adutinio_phase_are_you_willing_to_serve_on_the_eaglekidz_paw_team"
              ) + " ?"
            }
            value={wantPawKidz}
            options={[
              {
                value: "1",
                description: translate("yes"),
              },
              {
                value: "0",
                description: translate("no"),
              },
            ]}
            onChange={onChangeWantPawKidz}
          />

          <SubmitComponent
            disabled={submitDisabled}
            type={"submit"}
            text={translate("save_changes")}
            icon={<FaSave className="ms-2" />}
            sortBy={"right"}
          />
        </Card>
      </Form>
    </>
  );
}
