import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import LabelComponent from "./LabelComponent";

export default function InputRadioComponent({
  number,
  name,
  label,
  value,
  options,
  inline = true,
  required = true,
  noMargin = false,
  onChange,
}) {
  return (
    <FormGroup noMargin={noMargin}>
      <div>
        <LabelComponent
          number={number}
          name={name}
          label={label}
          required={required}
        />
      </div>

      {options.map((option, key) => (
        <FormGroup key={key} check inline={inline}>
          <Label check for={name + "_" + key}>
            <Input
              className="border border-1 border-dark"
              type="radio"
              id={name + "_" + key}
              name={name}
              value={option.value}
              checked={value === option.value}
              required={required}
              onChange={onChange}
            />
            <div>{option.description}</div>
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
  );
}
