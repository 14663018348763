import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export default function ButtonComponent({
  disabled = false,
  color = "primary",
  type = "button",
  outline = false,
  className = "w-100 mt-3",
  link,
  text,
  icon,
  sortBy = "left",
  onClick,
}) {
  return (
    <Link to={disabled ? null : link}>
      <Button
        className={disabled ? "disabled " + className : className}
        type={type}
        color={disabled ? "secondary" : color}
        outline={outline}
        onClick={onClick}
      >
        {sortBy === "left" ? (
          <>
            <span>{text}</span> <span>{icon}</span>
          </>
        ) : (
          <>
            <span>{icon}</span> <span>{text}</span>
          </>
        )}
      </Button>
    </Link>
  );
}
