import React from "react";

import moment from "moment";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  CardText,
} from "reactstrap";
import { FaArrowRight } from "react-icons/fa";

import StepUtil from "../../utils/StepUtil";

import ButtonComponent from "../ButtonComponent";

export default function TermsAndConditionsComponent({ translate, event }) {
  return (
    <>
      <Card color="primary" outline className="my-2">
        <CardHeader className="bg-primary text-light text-center">
          {translate("terms_and_conditions")}
        </CardHeader>
        <CardBody>
          <CardTitle tag="h6">
            {translate("from")} : {moment(event.openDate).format("LL")}
          </CardTitle>
          <CardTitle tag="h6">
            {translate("to")} : {moment(event.closeDate).format("LL")}
          </CardTitle>

          <hr />

          <CardText
            dangerouslySetInnerHTML={{
              __html: event.termsAndConditions,
            }}
          ></CardText>
        </CardBody>

        <CardFooter className="text-center">
          {translate("terms_and_conditions")}
        </CardFooter>
      </Card>

      <ButtonComponent
        link={"/profile"}
        text={translate("agree_and_continue")}
        icon={<FaArrowRight className="ms-2" />}
        onClick={() => StepUtil("Profile")}
      />
    </>
  );
}
