import React from "react";
import { Alert, Button, Card, CardTitle, Col, Row } from "reactstrap";

export default function DivisionComponent({
  translate,
  event,
  eventDetails,
  search,
  searchType,
  ministryTypeId,
  ministryId,
  ministryName,
  firstDivisionId,
  step,
  type,
  onClick,
}) {
  let text = translate("division");

  if (event.servolutionTypeId !== 2) {
    if (step === 2) {
      text = translate("second_division");
    } else {
      text = translate("first_division");
    }
  }

  return (
    <>
      <Alert
        className="text-center"
        color={ministryTypeId === type.Audition ? "primary" : "info"}
        fade={false}
      >
        {ministryName}
      </Alert>

      <Alert color="light" className="text-center mb-2" fade={false}>
        {translate("choose")} {text}
      </Alert>

      <Row>
        {eventDetails
          .filter((eventDetail) =>
            ministryId ? eventDetail.ministry.id === ministryId : true
          )
          .map((eventDetail) =>
            eventDetail.ministry.divisions
              .filter((division) =>
                step === 2 ? division.ministryTypeId === type.Interview : true
              )
              .filter((division) =>
                step === 2
                  ? event.secondOptionMinistries.includes(division.id)
                  : true
              )
              .filter((division) =>
                searchType ? division.ministryTypeId === searchType : true
              )
              .filter((division) =>
                division.name.toLowerCase().includes(search.toLowerCase())
              )
              .filter((division) =>
                firstDivisionId ? division.id !== firstDivisionId : true
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((division, key) => (
                <Col key={key} xs={6} className="my-2">
                  <Card
                    body
                    className="text-center h-100"
                    color={
                      division.ministryTypeId === type.Audition
                        ? "primary"
                        : "info"
                    }
                    outline
                  >
                    <CardTitle tag="h6">{division.name}</CardTitle>
                    <div className="mt-auto">
                      <div className="my-2 text-muted small" tag="h6">
                        {division.schedule.length} {translate("schedule")}
                      </div>
                      <Button
                        className="w-100 text-white"
                        color={
                          division.ministryTypeId === type.Audition
                            ? "primary"
                            : "info"
                        }
                        size="sm"
                        onClick={() =>
                          onClick(
                            division.ministryTypeId,
                            division.id,
                            division.name,
                            division.description
                          )
                        }
                      >
                        {translate("choose")} {translate("division")}
                      </Button>
                    </div>
                  </Card>
                </Col>
              ))
          )}
      </Row>
    </>
  );
}
