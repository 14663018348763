import React from "react";
import { InputGroup, Input, InputGroupText } from "reactstrap";
import { FaSearch } from "react-icons/fa";

export default function SearchComponent({ translate, onChangeSearch, search }) {
  return (
    <InputGroup className="mb-3">
      <InputGroupText>
        <FaSearch />
      </InputGroupText>

      <Input
        type="search"
        id="search"
        name="search"
        placeholder={
          translate("search") +
          " " +
          translate("ministry") +
          " " +
          translate("or") +
          " " +
          translate("division")
        }
        maxLength="100"
        onChange={onChangeSearch}
        value={search}
      />
    </InputGroup>
  );
}
