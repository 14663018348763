import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import CheckCookiesUtil from "../../utils/CheckCookiesUtil";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import RegistrationApprovalDetailsService from "../../services/RegistrationApprovalDetailsService";

import RegistrationApprovalComponent from "../../components/Registration/RegistrationApprovalComponent";
import LoaderComponent from "../../components/LoaderComponent";

const cookies = new Cookies();

export default function ApprovalPage({ translate, setIsLoading, account }) {
  const titlePage = translate("registration_approval");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const [registrationApproval, setRegistrationApproval] = useState(null);

  const params = new URLSearchParams(window.location.search);
  const event_id = params.get("event_id");
  const person_id = params.get("person_id");
  const leader_id = params.get("leader_id");

  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

  cookies.set("referrer_url", window.location.href, {
    expires: expirationDate,
    path: "/",
  });

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    if (!event_id && !person_id && !leader_id) {
      return navigate("/registration/not-exists");
    }

    const fetchData = async () => {
      const registrationApproval = await RegistrationApprovalDetailsService({
        event_id: event_id,
        person_id: person_id,
        leader_id: leader_id,
        account: account,
        navigate: navigate,
      });
      setRegistrationApproval(registrationApproval);
    };

    fetchData();

    setIsLoading(false);
  }, [
    translate,
    setIsLoading,
    account,
    navigate,
    event_id,
    person_id,
    leader_id,
  ]);

  if (!account) {
    return <LoaderComponent translate={translate} />;
  }

  if (!registrationApproval) {
    return <LoaderComponent translate={translate} />;
  }

  cookies.remove("referrer_url", { path: "/" });

  return (
    <RegistrationApprovalComponent
      translate={translate}
      event={registrationApproval.event}
      registration={registrationApproval.registration}
      event_id={event_id}
      person_id={person_id}
      leader_id={leader_id}
    />
  );
}
