import Cookies from "universal-cookie";
import VolunteerApi from "../api/VolunteerApi";

const cookies = new Cookies();

export default async function VolunteerActiveService({ account, navigate }) {
  try {
    const person_id = account.account.mygms_id;

    const volunteer = await VolunteerApi({ person_id });

    const result = volunteer.data.result;

    cookies.set("volunteer", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "inactive") {
      return navigate("/");
    }

    return result;
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
    cookies.set("volunteer", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (!result) {
      cookies.set("error_title", "Volunteer Active", {
        expires: new Date(cookies.get("expired")),
        path: "/",
      });

      cookies.set("error_description", result ?? message, {
        expires: new Date(cookies.get("expired")),
        path: "/",
      });

      return navigate("/error");
    }

    return result;
  }
}
