import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function PatchPawApi({ registrant_id, want_paw_kidz }) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const data = {
    want_paw_kidz: want_paw_kidz,
  };

  const url = `${api_url}/paw?token=${token}&registration_id=${registrant_id}`;

  return await axios.patch(url, data, { headers });
}
