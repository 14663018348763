import React from "react";
import AlertComponent from "../Home/AlertComponent";
import TermsAndConditionsComponent from "../Home/TermsAndConditionsComponent";

export const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  translate,
  event,
  navigate,
}) => {
  if (completed) {
    return navigate("/event-closed"); // COMMENT THIS FOR TESTING
  } else {
    return (
      <>
        <AlertComponent
          translate={translate}
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />

        <TermsAndConditionsComponent translate={translate} event={event} />
      </>
    );
  }
};
