import React from "react";
import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";

export default function UserInformationComponent({ translate, registration }) {
  return (
    <Card color="warning" outline className="mt-3">
      <CardHeader className="bg-warning text-white text-center">
        {translate("connect_group_information")}
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem>
          <div className="fw-bold">{translate("category")}</div>
          <div>{registration?.connectGroupCategory}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("name")}</div>
          <div>{registration?.connectGroupName}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("level")}</div>
          <div>{registration?.connectGroupLevel}</div>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}
