import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";

import { title } from "../utils/ConstantsUtil";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import useDocumentTitle from "../hooks/useDocumentTitle";

import NotFound from "../assets/images/404-not-found.png";

export default function Error404Page({ translate, setIsLoading }) {
  const titlePage = translate("page_not_found");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [setIsLoading]);

  return (
    <>
      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={NotFound}
          alt={titlePage + " - " + title}
        />

        <h2 className="fw-bold">{translate("error")}</h2>
        <h4>
          {translate("404")} - {translate("not_found")}
        </h4>
        <p>{translate("the_page_you_requested_could_not_be_found")}</p>

        <Button color="dark" className="my-2" onClick={() => navigate(-1)}>
          <FaArrowLeft className="me-2" />
          {translate("back_to_previous_page")}
        </Button>
      </div>
    </>
  );
}
