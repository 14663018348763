import moment from "moment";
import Cookies from "universal-cookie";
import EventActiveApi from "../api/EventActiveApi";

const cookies = new Cookies();

export default async function EventService({
  account,
  navigate,
  checkEventClosed = true,
}) {
  try {
    const church_id =
      cookies.get("church_id") ?? account.account.church.church_id;

    const event = await EventActiveApi({ church_id });

    const result = event.data.result[0];

    cookies.set("event", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    const today = moment().format("YYYY-MM-DD");

    const open_date = result.openDate;
    // const open_date = today; // TESTING

    const close_date = result.closeDate;
    // const close_date = today; // TESTING

    const openDate = moment(open_date).format("YYYY-MM-DD");
    const closeDate = moment(close_date).format("YYYY-MM-DD");

    if (checkEventClosed) {
      if (openDate > today || today > closeDate) {
        return navigate("/event-closed"); // COMMENT THIS FOR TESTING
      }
    }

    return result;
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("event", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "event inactive") {
      navigate("/event-closed"); // COMMENT THIS FOR TESTING
    }

    cookies.set("error_title", "Event Service", {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_description", result ?? message, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    navigate("/error");

    throw new Error(error);
  }
}
