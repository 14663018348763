import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function RegistrationApprovalDetailsApi({
  event_id,
  person_id,
  leader_id,
}) {
  return await axios.get(api_url + "/registration/approval", {
    params: {
      token: token,
      event_id: event_id,
      person_id: person_id,
      leader_id: leader_id,
    },
  });
}
