import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function VolunteerApi({ person_id }) {
  return await axios.get(api_url + "/volunteer", {
    params: {
      token: token,
      person_id: person_id,
    },
  });
}
