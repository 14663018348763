import React from "react";
import moment from "moment";
import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";

export default function UserInformationComponent({
  translate,
  event,
  registration,
}) {
  return (
    <Card color="primary" outline>
      <CardHeader className="bg-primary text-white text-center">
        {translate("event_information")}
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem>
          <div className="fw-bold">{translate("church_name")}</div>
          <div>{event.churchName}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("servolution_type")}</div>
          <div>
            {event.servolutionTypeId === 1
              ? translate("first_and_second_option")
              : translate("one_option_only")}
          </div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("open_date")}</div>
          <div>{moment(event.openDate).format("LL")}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("close_date")}</div>
          <div>{moment(event.closeDate).format("LL")}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("maximum_approval_date")}</div>
          <div>{moment(event.maximumApprovalDate).format("LL")}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("service_category")}</div>
          <div>{translate(registration?.serviceCategory)}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("first_option_ministry")}</div>
          <div>
            {registration?.firstMinistry.ministryTypeId === 1
              ? translate("audition_and_interview")
              : translate("interview_only")}
          </div>
          <div>{registration?.firstMinistry.ministries.name}</div>
          <div>{registration?.firstMinistry.ministries.description}</div>
          <div>{registration?.firstMinistry.name}</div>
          <div>{registration?.firstMinistry.description}</div>
        </ListGroupItem>

        {registration?.secondMinistry ? (
          <ListGroupItem>
            <div className="fw-bold">{translate("second_option_ministry")}</div>
            <div>
              {registration?.secondMinistry.ministryTypeId === 1
                ? translate("audition_and_interview")
                : translate("interview_only")}
            </div>
            <div>{registration?.secondMinistry.ministries.name}</div>
            <div>{registration?.secondMinistry.ministries.description}</div>
            <div>{registration?.secondMinistry.name}</div>
            <div>{registration?.secondMinistry.description}</div>
          </ListGroupItem>
        ) : null}
      </ListGroup>
    </Card>
  );
}
