import React, { useEffect } from "react";

import Cookies from "universal-cookie";

import CheckCookiesUtil from "../../utils/CheckCookiesUtil";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import RegistrationComponent from "../../components/Registration/RegistrationComponent";
import LoaderComponent from "../../components/LoaderComponent";

import RegistrationApproved from "../../assets/images/registration/approved.png";

const cookies = new Cookies();

export default function ApprovedPage({ translate, setIsLoading, account }) {
  const titlePage = translate("registration_approved");
  useDocumentTitle(titlePage);

  cookies.remove("referrer_url", { path: "/" });

  useEffect(() => {
    cookies.remove("referrer_url", { path: "/" });
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [translate, setIsLoading]);

  if (!account) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <RegistrationComponent
      titlePage={titlePage}
      name={translate("registration_approved")}
      description={translate("this_registration_has_been_approved")}
      image={RegistrationApproved}
    />
  );
}
