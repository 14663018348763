export const api_url = process.env.REACT_APP_API_URL;
export const auth_url = process.env.REACT_APP_AUTH_URL;
export const file_url = process.env.REACT_APP_FILE_URL;
export const header_content_type = process.env.REACT_APP_HEADER_CONTENT_TYPE;
export const header_accept = process.env.REACT_APP_HEADER_ACCEPT;
export const header_key = process.env.REACT_APP_HEADER_KEY;
export const response_type = process.env.REACT_APP_RESPONSE_TYPE;
export const client_id = process.env.REACT_APP_CLIENT_ID;
export const redirect_uri = process.env.REACT_APP_REDIRECT_URL;
export const gms_churches = process.env.REACT_APP_GMS_CHURCHES;
export const scope = process.env.REACT_APP_SCOPE;
export const state = process.env.REACT_APP_STATE;
export const type = process.env.REACT_APP_TYPE;
export const token = process.env.REACT_APP_TOKEN;
export const name = process.env.REACT_APP_NAME;
export const title = process.env.REACT_APP_TITLE;
export const max_image_size = process.env.REACT_APP_MAX_IMAGE_SIZE;
export const max_image_size_text = process.env.REACT_APP_MAX_IMAGE_SIZE_TEXT;
export const accept_image = process.env.REACT_APP_ACCEPT_IMAGE;
export const image_type = process.env.REACT_APP_IMAGE_TYPE;
export const image_type_text = process.env.REACT_APP_IMAGE_TYPE_TEXT;
export const eaglekidz_ministry_id =
  process.env.REACT_APP_EAGLEKIDZ_MINISTRY_ID;
export const paw_ministry_id = process.env.REACT_APP_PAW_MINISTRY_ID;
export const church_regional_id = process.env.REACT_APP_CHURCH_REGIONAL_ID;
export const city_url = process.env.REACT_APP_CITY_URL;
export const header_x_type_sense_api_key =
  process.env.REACT_APP_HEADER_X_TYPESENSE_API_KEY;

export const super_user = "a360265027fc7ba3f08938443bd03b62";
export const ids_church_remove_check_church_component = [
  "a360265027fc7ba3f08938443bd60e5c",
  "a360265027fc7ba3f08938443bd619dd",
  "42b9b3667fccacef0e2a4a7817526304",
  "42b9b3667fccacef0e2a4a78171c9ceb",
];

export const ServolutionType = {
  FirstOption: -1,
  Optional: 0,
  SecondOption: 1,
};

export const Type = {
  All: 0,
  Audition: 1,
  Interview: 2,
};
