import React from "react";
import LogoSquare from "../assets/images/logo-white.png";
import { title, name } from "../utils/ConstantsUtil";

export default function LoaderComponent({ translate }) {
  return (
    <div className="vh-100 bg-dark text-white position-fixed top-0 start-0 w-100">
      <div className="row h-100">
        <div className="col-10 col-md-6 col-lg-5 col-xl-8 mx-auto text-center align-self-center">
          <div>
            <img
              draggable="false"
              alt={"Logo White - " + title}
              src={LogoSquare}
              width="100"
            />
          </div>
          <h1 className="mt-2 fw-bold text-uppercase">{name}</h1>
          <h5 className="text-uppercase font-monospace">
            {translate("year")} {new Date().getFullYear()}
          </h5>
          <div className="spinner-border my-4" role="status"></div>
          <h3>{translate("welcome_home")}</h3>
          <h6>{translate("now_loading")}...</h6>
        </div>
      </div>
    </div>
  );
}
