import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import { church_regional_id } from "../utils/ConstantsUtil";
import StepUtil from "../utils/StepUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import CheckRegistrationStatusService from "../services/CheckRegistrationStatusService";
import CheckVolunteerService from "../services/CheckVolunteerService";
import EventService from "../services/EventService";
import SaveRegistrationFailService from "../services/SaveRegistrationFailService";

import AlertComponent from "../components/Profile/AlertComponent";
import ButtonComponent from "../components/ButtonComponent";
import LoaderComponent from "../components/LoaderComponent";
import RequirementComponent from "../components/Profile/RequirementComponent";
import ShallomComponent from "../components/Profile/ShallomComponent";
import { Card } from "reactstrap";
import InputRadioComponent from "../components/InputRadioComponent";

const cookies = new Cookies();

export default function ProfilePage({ translate, setIsLoading, account }) {
  const titlePage = translate("profile");
  useDocumentTitle(titlePage);

  let disabled = true;

  const navigate = useNavigate();

  const [event, setEvent] = useState(null);

  const [isHolySpiritBaptized, setIsHolySpiritBaptized] = useState(
    cookies.get("is_holy_spirit_baptized")
  );

  const onChangeIsHolySpiritBaptized = (e) => {
    const value = e.target.value;
    setIsHolySpiritBaptized(value);
    cookies.set("is_holy_spirit_baptized", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    if (cookies.get("step") !== "Profile") {
      return navigate("/");
    }

    if (cookies.get("step") === "Holy Spirit Baptized") {
      return navigate("/holy-spirit-baptized");
    }

    const fetchData = async () => {
      try {
        await CheckVolunteerService({ account: account, navigate: navigate });

        const event = await EventService({
          account: account,
          navigate: navigate,
        });
        setEvent(event);

        await CheckRegistrationStatusService({
          account: account,
          event: event,
          navigate: navigate,
        });
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();

    setIsLoading(false);
  }, [translate, setIsLoading, navigate, account]);

  if (!event) {
    return <LoaderComponent translate={translate} />;
  }

  let cg = false;
  let cgColor = "danger";
  let cgText = translate("inactive");

  if (account.account.connect_groups) {
    cg = true;
    cgColor = "success";
    cgText = translate("active");
  }

  let phoneNumber = null;
  let phoneNumberColor = "danger";
  let phoneNumberText = translate("not_verified");

  if (account.account.cell_phone.verified) {
    phoneNumber = account.account.cell_phone.number;
    phoneNumberColor = "success";
    phoneNumberText = translate("verified") + " (" + phoneNumber + ")";
  }

  let emailAddress = null;
  let emailAddressColor = "danger";
  let emailAddressText = translate("not_verified");

  if (account.account.email.verified) {
    emailAddress = account.account.email.address;
    emailAddressColor = "success";
    emailAddressText = translate("verified") + " (" + emailAddress + ")";
  }

  let nij = null;
  let nijColor = "danger";
  let nijText = translate("inactive");

  if (account.account.nij) {
    nij = account.account.nij;
    nijColor = "success";
    nijText = translate("registered") + " (" + nij + ")";
  }

  let msj = false;
  let msjColor = "danger";
  let msjText = translate("not_pass");

  let course = null;
  let course_id = null;
  let course_name = null;

  for (course = 0; course < account.account.certificates.length; course++) {
    if (event.msjRequirement === 1) {
      course_id = 1;
      course_name = "My Spiritual Journey 1";
    }
    if (event.msjRequirement === 2) {
      course_id = 2;
      course_name = "My Spiritual Journey 2";
    }
    if (event.msjRequirement === 3) {
      course_id = 3;
      course_name = "My Spiritual Journey 3";
    }
    if (event.msjRequirement === 4) {
      course_id = 4;
      course_name = "My Spiritual Journey 4";
    }
    if (
      account.account.certificates[course].course_id === course_id &&
      account.account.certificates[course].course_name === course_name
    ) {
      msj = true;
      msjColor = "success";
      msjText = translate("pass");
    }
  }

  let baptism = null;
  let baptismColor = "warning";
  let baptismText = translate("incomplete");

  if (account.account.water_baptism && account.account.water_baptism.where) {
    baptism = true;
    baptismColor = "success";
    baptismText = translate("complete");
  } else {
    baptism = false;
    baptismColor = "warning";
    baptismText = translate("incomplete");
  }

  if (!event.nijRequirement) {
    nij = true;
  }

  if (!event.msjRequirement) {
    msj = true;
  }

  if (!event.baptismRequirement) {
    baptism = true;
  }

  let buttonLink = "/water-baptism";
  let buttonColor = "info";
  let buttonText = "complete_the_baptism_form";
  let buttonIcon = <FaArrowRight className="ms-2" />;
  let buttonOnClick = () => StepUtil("Water Baptism");

  if (baptism) {
    buttonLink = "/ministry";
    buttonColor = "primary";
    buttonText = "choose_ministry";
    buttonIcon = <FaArrowRight className="ms-2" />;
    buttonOnClick = () => StepUtil("Ministry");
  }

  const onClick = async (e) => {
    window.scrollTo(0, 0);

    e.preventDefault();

    const data = {
      person_id: account.account.mygms_id,
      nij: account.account.nij,
      name: account.account.name,
      birth_date: account.account.birth_date,
      gender: account.account.gender,
      email: account.account.email.address,
      phone: account.account.cell_phone.number,
      connect_group_category: account.connect_group_category[0],
      connect_group_name: account.connect_group_name[0],
      connect_group_level: account.connect_group_level[0],
      connect_group_path: account.cg_path,
      church_id: account.account.church.church_id,
      church_name: account.account.church.name,
    };

    await SaveRegistrationFailService({
      event,
      data,
      navigate,
    });
  };

  if (account.account.church.church_regional_id === church_regional_id) {
    if (isHolySpiritBaptized === "Tidak") {
      disabled = false;
      buttonOnClick = onClick;
    } else if (isHolySpiritBaptized) {
      disabled = false;
    }
  } else {
    disabled = false;
  }

  return (
    <>
      <ShallomComponent translate={translate} account={account} />

      <RequirementComponent
        color={cgColor}
        text={cgText}
        title={translate("connect_group")}
      />

      <RequirementComponent
        color={phoneNumberColor}
        text={phoneNumberText}
        title={translate("phone_number")}
      />

      <RequirementComponent
        color={emailAddressColor}
        text={emailAddressText}
        title={translate("email_address")}
      />

      {event.nijRequirement ? (
        <RequirementComponent
          color={nijColor}
          text={nijText}
          title={translate("nij")}
        />
      ) : null}

      {event.msjRequirement ? (
        <RequirementComponent
          color={msjColor}
          text={msjText}
          title={translate("msj") + " " + event.msjRequirement}
        />
      ) : null}

      {event.baptismRequirement ? (
        <RequirementComponent
          color={baptismColor}
          text={baptismText}
          title={translate("water_baptism")}
        />
      ) : null}

      {account.account.church.church_regional_id === church_regional_id ? (
        <Card body color="primary" outline className="mb-3 mt-3">
          <InputRadioComponent
            number={""}
            name={"is_holy_spirit_baptized"}
            label={
              translate(
                "have_you_received_the_baptism_of_the_holy_spirit_with_the_sign_of_speaking_in_tongues"
              ) + " ?"
            }
            value={isHolySpiritBaptized}
            options={[
              {
                value: "Ya",
                description: translate("yes"),
              },
              {
                value: "Tidak",
                description: translate("no"),
              },
            ]}
            onChange={onChangeIsHolySpiritBaptized}
          />
        </Card>
      ) : null}

      {cg && nij && msj ? (
        <ButtonComponent
          disabled={disabled}
          link={buttonLink}
          className={"w-100"}
          color={buttonColor}
          text={translate(buttonText)}
          icon={buttonIcon}
          onClick={buttonOnClick}
        />
      ) : (
        <AlertComponent translate={translate} />
      )}

      <ButtonComponent
        link={"/"}
        color={"light"}
        text={translate("back_to") + " " + translate("home")}
        icon={<FaArrowLeft className="me-2" />}
        sortBy={"right"}
        onClick={() => StepUtil("Home")}
      />
    </>
  );
}
