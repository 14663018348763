import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "reactstrap";
import RegistrationStatusMinistryScheduleComponent from "./RegistrationStatusMinistryScheduleComponent";

export default function RegistrationStatusMinistryComponent({
  translate,
  data,
  color,
}) {
  if (data.ministry !== "-" && data.division !== "-") {
    return (
      <Card color={color} outline className="mt-4">
        <CardHeader className={"bg-" + color + " text-white text-center"}>
          {translate("ministry")}
        </CardHeader>
        <CardBody>
          <CardTitle tag="h5">{data.ministry}</CardTitle>

          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {data.division}
          </CardSubtitle>

          <RegistrationStatusMinistryScheduleComponent
            translate={translate}
            schedule={data.schedule}
            type={data.type}
          />
        </CardBody>
      </Card>
    );
  }
}
