import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import RegistrationAttachmentApi from "../api/RegistrationAttachmentApi";

const cookies = new Cookies();

export default async function RegistrationAttachmentService({
  translate,
  formData,
}) {
  try {
    const registrationAttachment = await RegistrationAttachmentApi({
      formData,
    });

    const blobName = registrationAttachment.data.blobName;

    cookies.set("registration_attachment", blobName, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    Swal.fire({
      title: translate("success"),
      text: translate("The image has been successfully uploaded"),
      icon: "success",
      confirmButtonText: translate("close"),
      footer: translate("mawar_sharon_church"),
      allowOutsideClick: false,
    });

    return blobName;
  } catch (error) {
    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    Swal.fire({
      title: translate("error"),
      text: error.message,
      icon: "error",
      confirmButtonText: translate("please_try_again"),
      footer: translate("mawar_sharon_church"),
      allowOutsideClick: false,
    });

    return null;
  }
}
