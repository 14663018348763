import React, { useState } from "react";

import Cookies from "universal-cookie";
import Swal from "sweetalert2";

import { Form, FormGroup, Label } from "reactstrap";
import { FaPaperPlane } from "react-icons/fa";

import {
  accept_image,
  church_regional_id,
  eaglekidz_ministry_id,
  file_url,
  image_type_text,
  image_type,
  max_image_size_text,
  max_image_size,
  paw_ministry_id,
} from "../../utils/ConstantsUtil";

import RegistrationAttachmentService from "../../services/RegistrationAttachmentService";
import SaveRegistrationService from "../../services/SaveRegistrationService";

import InputFileComponent from "../InputFileComponent";
import PreviewComponent from "../PreviewComponent";

import AgreeComponent from "../AgreeComponent";
import SubmitComponent from "../SubmitComponent";
import InputRadioComponent from "../InputRadioComponent";
import InputTextareaComponent from "../InputTextareaComponent";
import CityOfDomicileComponent from "./CityOfDomicileComponent";

const cookies = new Cookies();

export default function FormComponent({ translate, account, event, navigate }) {
  let number = 1;

  const [photo, setPhoto] = useState(cookies.get("photo"));

  const [photoInvalid, setPhotoInvalid] = useState(null);

  const upload = async (e) => {
    const image = e.target.files[0];

    if (image.size > max_image_size) {
      setPhotoInvalid(
        translate("image_size_more_than") + " " + max_image_size_text
      );
      return;
    }

    if (!image_type.includes(image.type)) {
      setPhotoInvalid(
        translate("format_image_not_valid") +
          ". " +
          translate("only") +
          " " +
          image_type_text
      );
      return;
    }

    const formData = new FormData();
    formData.append("attachment", image);

    const photo = await RegistrationAttachmentService({
      translate,
      formData,
    });

    if (photo === null) {
      setPhotoInvalid(
        translate("upload") +
          " " +
          translate("photo") +
          " " +
          translate("error")
      );
      return;
    }

    cookies.set("photo", photo, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    setPhotoInvalid(null);
    setPhoto(photo);
  };

  const [serviceCategory, setServiceCategory] = useState(
    cookies.get("service_category")
  );

  const [artiPelayanan, setArtiPelayanan] = useState(
    cookies.get("arti_pelayanan") ?? ""
  );

  const [alasanPelayanan, setAlasanPelayanan] = useState(
    cookies.get("alasan_pelayanan") ?? ""
  );

  const [pengalamanBidang, setPengalamanBidang] = useState(
    cookies.get("pengalaman_bidang")
  );

  const [berapaLamaMenekuniBidangIni, setBerapaLamaMenekuniBidangIni] =
    useState(cookies.get("berapa_lama_menekuni_bidang_ini"));

  const [prestasiDalamBidang, setPrestasiDalamBidang] = useState(
    cookies.get("prestasi_dalam_bidang")
  );

  const [bersediaMeluangkanWaktu, setBersediaMeluangkanWaktu] = useState(
    cookies.get("bersedia_meluangkan_waktu")
  );

  const [keluargaMendukung, setKeluargaMendukung] = useState(
    cookies.get("keluarga_mendukung")
  );

  const [dimanaBelajarBidang, setDimanaBelajarBidang] = useState(
    cookies.get("dimana_belajar_bidang") ?? ""
  );

  const [hobby, setHobby] = useState(cookies.get("hobby") ?? "");

  const [aktivitasNyataHobby, setAktivitasNyataHobby] = useState(
    cookies.get("aktivitas_nyata_hobby") ?? ""
  );

  const [kegiatanMenyitaWaktu, setKegiatanMenyitaWaktu] = useState(
    cookies.get("kegiatan_menyita_waktu") ?? ""
  );

  const [kegiatanMempengaruhiPelayanan, setKegiatanMempengaruhiPelayanan] =
    useState(cookies.get("kegiatan_mempengaruhi_pelayanan"));

  const [pelayananDiGerejaLain, setPelayananDiGerejaLain] = useState(
    cookies.get("pelayanan_di_gereja_lain")
  );

  const [apaYangInginDicapaiDalam5Tahun, setApaYangInginDicapaiDalam5Tahun] =
    useState(cookies.get("apa_yang_ingin_dicapai_dalam_5_tahun") ?? "");

  const [susunanPrioritas, setSusunanPrioritas] = useState(
    cookies.get("susunan_prioritas") ?? ""
  );

  const [kekuatanKelemahan, setKekuatanKelemahan] = useState(
    cookies.get("kekuatan_kelemahan") ?? ""
  );

  const [talenta, setTalenta] = useState(cookies.get("talenta") ?? "");

  const [talentaMenjadiMotivasi, setTalentaMenjadiMotivasi] = useState(
    cookies.get("talenta_menjadi_motivasi")
  );

  const [skillKhusus, setSkillKhusus] = useState(
    cookies.get("skill_khusus") ?? ""
  );

  const [pendidikanTerakhir, setPendidikanTerakhir] = useState(
    cookies.get("pendidikan_terakhir") ?? ""
  );

  const [valueHidup, setValueHidup] = useState(
    cookies.get("value_hidup") ?? ""
  );

  const [pergumulanHubungan, setPergumulanHubungan] = useState(
    cookies.get("pergumulan_hubungan")
  );

  const [pergumulanPekerjaanStudi, setPergumulanPekerjaanStudi] = useState(
    cookies.get("pergumulan_pekerjaan_studi")
  );

  const [pergumulanKesehatan, setPergumulanKesehatan] = useState(
    cookies.get("pergumulan_kesehatan")
  );

  const [pergumulanKeuangan, setPergumulanKeuangan] = useState(
    cookies.get("pergumulan_keuangan")
  );

  const [rutinSaatTeduh, setRutinSaatTeduh] = useState(
    cookies.get("rutin_saat_teduh")
  );

  const [namaRohaniawan, setNamaRohaniawan] = useState(
    cookies.get("nama_rohaniawan") ?? ""
  );

  const [pendapatMinumanBeralkohol, setPendapatMinumanBeralkohol] = useState(
    cookies.get("pendapat_minuman_beralkohol")
  );

  const [pendapatMerokok, setPendapatMerokok] = useState(
    cookies.get("pendapat_merokok")
  );

  const [pendapatPerceraian, setPendapatPerceraian] = useState(
    cookies.get("pendapat_perceraian")
  );

  const [pendapatAborsi, setPendapatAborsi] = useState(
    cookies.get("pendapat_aborsi")
  );

  const [pendapatHomoSeksual, setPendapatHomoseksual] = useState(
    cookies.get("pendapat_homoseksual")
  );

  const [pendapatHutangPribadi, setPendapatHutangPribadi] = useState(
    cookies.get("pendapat_hutang_pribadi")
  );

  const [pendapatTatoo, setPendapatTatoo] = useState(
    cookies.get("pendapat_tatoo")
  );

  const [status, setStatus] = useState(cookies.get("status"));

  const [namaPasangan, setNamaPasangan] = useState(
    cookies.get("nama_pasangan") ?? ""
  );

  const [ayatFavorit, setAyatFavorit] = useState(
    cookies.get("ayat_favorit") ?? ""
  );

  const [agree, setAgree] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onChangeServiceCategory = (e) => {
    const value = e.target.value;
    setServiceCategory(value);
    cookies.set("service_category", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeArtiPelayanan = (e) => {
    const value = e.target.value;
    setArtiPelayanan(value);
    cookies.set("arti_pelayanan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeAlasanPelayanan = (e) => {
    const value = e.target.value;
    setAlasanPelayanan(value);
    cookies.set("alasan_pelayanan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePengalamanBidang = (e) => {
    const value = e.target.value;
    setPengalamanBidang(value);
    cookies.set("pengalaman_bidang", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeBerapaLamaMenekuniBidangIni = (e) => {
    const value = e.target.value;
    setBerapaLamaMenekuniBidangIni(value);
    cookies.set("berapa_lama_menekuni_bidang_ini", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePrestasiDalamBidang = (e) => {
    const value = e.target.value;
    setPrestasiDalamBidang(value);
    cookies.set("prestasi_dalam_bidang", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeBersediaMeluangkanWaktu = (e) => {
    const value = e.target.value;
    setBersediaMeluangkanWaktu(value);
    cookies.set("bersedia_meluangkan_waktu", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeKeluargaMendukung = (e) => {
    const value = e.target.value;
    setKeluargaMendukung(value);
    cookies.set("keluarga_mendukung", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeDimanaBelajarBidang = (e) => {
    const value = e.target.value;
    setDimanaBelajarBidang(value);
    cookies.set("dimana_belajar_bidang", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeHobby = (e) => {
    const value = e.target.value;
    setHobby(value);
    cookies.set("hobby", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeAktivitasNyataHobby = (e) => {
    const value = e.target.value;
    setAktivitasNyataHobby(value);
    cookies.set("aktivitas_nyata_hobby", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeKegiatanMenyitaWaktu = (e) => {
    const value = e.target.value;
    setKegiatanMenyitaWaktu(value);
    cookies.set("kegiatan_menyita_waktu", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeKegiatanMempengaruhiPelayanan = (e) => {
    const value = e.target.value;
    setKegiatanMempengaruhiPelayanan(value);
    cookies.set("kegiatan_mempengaruhi_pelayanan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePelayananDiGerejaLain = (e) => {
    const value = e.target.value;
    setPelayananDiGerejaLain(value);
    cookies.set("pelayanan_di_gereja_lain", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeApaYangInginDicapaiDalam5Tahun = (e) => {
    const value = e.target.value;
    setApaYangInginDicapaiDalam5Tahun(value);
    cookies.set("apa_yang_ingin_dicapai_dalam_5_tahun", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeSusunanPrioritas = (e) => {
    const value = e.target.value;
    setSusunanPrioritas(value);
    cookies.set("susunan_prioritas", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeKekuatanKelemahan = (e) => {
    const value = e.target.value;
    setKekuatanKelemahan(value);
    cookies.set("kekuatan_kelemahan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeTalenta = (e) => {
    const value = e.target.value;
    setTalenta(value);
    cookies.set("talenta", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeTalentaMenjadiMotivasi = (e) => {
    const value = e.target.value;
    setTalentaMenjadiMotivasi(value);
    cookies.set("talenta_menjadi_motivasi", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeSkillKhusus = (e) => {
    const value = e.target.value;
    setSkillKhusus(value);
    cookies.set("skill_khusus", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendidikanTerakhir = (e) => {
    const value = e.target.value;
    setPendidikanTerakhir(value);
    cookies.set("pendidikan_terakhir", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeValueHidup = (e) => {
    const value = e.target.value;
    setValueHidup(value);
    cookies.set("value_hidup", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePergumulanHubungan = (e) => {
    const value = e.target.value;
    setPergumulanHubungan(value);
    cookies.set("pergumulan_hubungan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePergumulanPekerjaanStudi = (e) => {
    const value = e.target.value;
    setPergumulanPekerjaanStudi(value);
    cookies.set("pergumulan_pekerjaan_studi", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePergumulanKesehatan = (e) => {
    const value = e.target.value;
    setPergumulanKesehatan(value);
    cookies.set("pergumulan_kesehatan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePergumulanKeuangan = (e) => {
    const value = e.target.value;
    setPergumulanKeuangan(value);
    cookies.set("pergumulan_keuangan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeRutinSaatTeduh = (e) => {
    const value = e.target.value;
    setRutinSaatTeduh(value);
    cookies.set("rutin_saat_teduh", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeNamaRohaniawan = (e) => {
    const value = e.target.value;
    setNamaRohaniawan(value);
    cookies.set("nama_rohaniawan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendapatMinumanBeralkohol = (e) => {
    const value = e.target.value;
    setPendapatMinumanBeralkohol(value);
    cookies.set("pendapat_minuman_beralkohol", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendapatMerokok = (e) => {
    const value = e.target.value;
    setPendapatMerokok(value);
    cookies.set("pendapat_merokok", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendapatPerceraian = (e) => {
    const value = e.target.value;
    setPendapatPerceraian(value);
    cookies.set("pendapat_perceraian", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendapatAborsi = (e) => {
    const value = e.target.value;
    setPendapatAborsi(value);
    cookies.set("pendapat_aborsi", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendapatHomoseksual = (e) => {
    const value = e.target.value;
    setPendapatHomoseksual(value);
    cookies.set("pendapat_homoseksual", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendapatHutangPribadi = (e) => {
    const value = e.target.value;
    setPendapatHutangPribadi(value);
    cookies.set("pendapat_hutang_pribadi", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangePendapatTatoo = (e) => {
    const value = e.target.value;
    setPendapatTatoo(value);
    cookies.set("pendapat_tatoo", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeStatus = (e) => {
    const value = e.target.value;
    setStatus(value);
    cookies.set("status", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
    if (value === "Married") {
      setNamaPasangan("");
      cookies.remove("nama_pasangan", {
        path: "/",
      });
    }
  };

  const onChangeNamaPasangan = (e) => {
    const value = e.target.value;
    setNamaPasangan(value);
    cookies.set("nama_pasangan", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeAyatFavorit = (e) => {
    const value = e.target.value;
    setAyatFavorit(value);
    cookies.set("ayat_favorit", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onClickAgree = (e) => {
    const checked = e.target.checked;
    setAgree(checked);
    cookies.set("agree", checked, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!account) {
      return Swal.fire({
        title: translate("your_session_is_expired"),
        text: translate("please_register_servolution_from_start"),
        icon: "warning",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!photo) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("photo") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!serviceCategory) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_2") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!artiPelayanan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_2") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!alasanPelayanan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_3") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pengalamanBidang) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_4") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!berapaLamaMenekuniBidangIni) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_5") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!prestasiDalamBidang) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_6") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!bersediaMeluangkanWaktu) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_7") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!keluargaMendukung) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_8") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!dimanaBelajarBidang) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("question_9") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!hobby) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_10") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!aktivitasNyataHobby) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_11") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!kegiatanMenyitaWaktu) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_12") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!kegiatanMempengaruhiPelayanan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_13") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pelayananDiGerejaLain) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_14") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!apaYangInginDicapaiDalam5Tahun) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_15") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!susunanPrioritas) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_16") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!kekuatanKelemahan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_17") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!talenta) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_18") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!talentaMenjadiMotivasi) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_19") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!skillKhusus) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_20") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendidikanTerakhir) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_21") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!valueHidup) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_22") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pergumulanHubungan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_23") +
          "<br />" +
          translate("relationship") +
          +"<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pergumulanPekerjaanStudi) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_23") +
          "<br />" +
          translate("work_or_study") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pergumulanKesehatan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_23") +
          "<br />" +
          translate("health") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pergumulanKeuangan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_23") +
          "<br />" +
          translate("finance") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!rutinSaatTeduh) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_24") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!namaRohaniawan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_25") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendapatMinumanBeralkohol) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_26") +
          "<br />" +
          translate("consume_alcoholic_drinks") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendapatMerokok) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_26") +
          "<br />" +
          translate("smoking") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendapatMerokok) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_26") +
          "<br />" +
          translate("divorce_and_remarriage") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendapatAborsi) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_26") +
          "<br />" +
          translate("forced_abortion") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendapatHomoSeksual) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_26") +
          "<br />" +
          translate("homosexual") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendapatHutangPribadi) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_26") +
          "<br />" +
          translate("personal_debt") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!pendapatTatoo) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html:
          translate("question_26") +
          "<br />" +
          translate("tatoo") +
          "<br />" +
          translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (!status) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("status") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    if (status === "Married" && !namaPasangan) {
      return Swal.fire({
        title: translate("form_validation_error"),
        html: translate("status") + "<br />" + translate("cannot_be_empty"),
        icon: "error",
        confirmButtonText: translate("ok"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }

    setSubmitDisabled(true);

    const data = {
      event_id: event.id,
      person_id: account.account.mygms_id,
      nij: account.account.nij,
      name: account.account.name,
      service_category: serviceCategory,

      baptism_date: cookies.get("baptism_date") ?? null,
      baptism_place: cookies.get("baptism_place") ?? null,
      certificate: cookies.get("certificate") ?? null,
      father_name: cookies.get("father_name") ?? null,
      mother_name: cookies.get("mother_name") ?? null,
      baptism_type: cookies.get("baptism_type") ?? null,

      birth_date: account.account.birth_date,
      gender: account.account.gender,
      email: account.account.email.address,
      phone: account.account.cell_phone.number,
      connect_group_category: account.connect_group_category[0],
      connect_group_name: account.connect_group_name[0],
      connect_group_level: account.connect_group_level[0],
      connect_group_path: account.cg_path,
      church_id: account.account.church.church_id,
      church_name: account.account.church.name,

      first_option_ministry: cookies.get("first_option_ministry"),
      first_option_ministry_schedule_id: cookies.get(
        "first_option_ministry_schedule_id"
      ),
      second_option_ministry: cookies.get("second_option_ministry") ?? null,
      second_option_ministry_schedule_id:
        cookies.get("second_option_ministry_schedule_id") ?? null,

      photo: photo,
      arti_pelayanan: artiPelayanan,
      alasan_pelayanan: alasanPelayanan,
      pengalaman_bidang: pengalamanBidang,
      berapa_lama_menekuni_bidang_ini: berapaLamaMenekuniBidangIni,
      prestasi_dalam_bidang: prestasiDalamBidang,
      bersedia_meluangkan_waktu: bersediaMeluangkanWaktu,
      keluarga_mendukung: keluargaMendukung,
      dimana_belajar_bidang: dimanaBelajarBidang,
      hobby: hobby,
      aktivitas_nyata_hobby: aktivitasNyataHobby,
      kegiatan_menyita_waktu: kegiatanMenyitaWaktu,
      kegiatan_mempengaruhi_pelayanan: kegiatanMempengaruhiPelayanan,
      pelayanan_di_gereja_lain: pelayananDiGerejaLain,
      apa_yang_ingin_dicapai_dalam_5_tahun: apaYangInginDicapaiDalam5Tahun,
      susunan_prioritas: susunanPrioritas,
      kekuatan_kelemahan: kekuatanKelemahan,
      talenta: talenta,
      talenta_menjadi_motivasi: talentaMenjadiMotivasi,
      skill_khusus: skillKhusus,
      pendidikan_terakhir: pendidikanTerakhir,
      value_hidup: valueHidup,
      pergumulan_hubungan: pergumulanHubungan,
      pergumulan_pekerjaan_studi: pergumulanHubungan,
      pergumulan_kesehatan: pergumulanHubungan,
      pergumulan_keuangan: pergumulanKeuangan,
      rutin_saat_teduh: rutinSaatTeduh,
      nama_rohaniawan: namaRohaniawan,
      pendapat_minuman_beralkohol: pendapatMinumanBeralkohol,
      pendapat_merokok: pendapatMerokok,
      pendapat_perceraian: pendapatPerceraian,
      pendapat_aborsi: pendapatAborsi,
      pendapat_homoseksual: pendapatHomoSeksual,
      pendapat_hutang_pribadi: pendapatHutangPribadi,
      pendapat_tatoo: pendapatTatoo,
      status: status,
      nama_pasangan: namaPasangan ?? null,
      ayat_favorit: ayatFavorit ?? null,
      is_holy_spirit_baptized: 1,
      want_paw_kidz: parseInt(cookies.get("want_paw_kidz") ?? 0),
      kota_domisili: cookies.get("kota_domisili"),
    };

    if (account.account.church.church_regional_id !== church_regional_id) {
      delete data["is_holy_spirit_baptized"];
      delete data["want_paw_kidz"];
      delete data["kota_domisili"];
    }

    if (cookies.get("first_ministry_id") !== paw_ministry_id) {
      delete data["want_paw_kidz"];
      delete data["kota_domisili"];
    }

    if (cookies.get("first_division_name")?.includes("Kidz") === true) {
      delete data["want_paw_kidz"];
    }

    // return console.log(data); // FOR TESTING ONLY

    await SaveRegistrationService({
      event: event,
      data: data,
      translate: translate,
      navigate: navigate,
    });

    setSubmitDisabled(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputFileComponent
        translate={translate}
        name={"photo"}
        label={translate("photo")}
        description={
          translate("format") +
          " " +
          image_type_text +
          " " +
          translate("max_size") +
          " " +
          max_image_size_text
        }
        image={file_url + "/photos/" + photo}
        feedback={photoInvalid}
        accept={accept_image}
        upload={upload}
      />

      <PreviewComponent translate={translate} name={"photo"} image={file_url + "/photos/" + photo} />

      <InputRadioComponent
        number={number++}
        name={"service_category"}
        label={translate("question_1")}
        value={serviceCategory}
        options={[
          {
            value: "Umum",
            description: translate("sunday_service"),
          },
          {
            value: "AOG",
            description: translate("aog"),
          },
          {
            value: "Eaglekidz",
            description: translate("eaglekidz"),
          },
        ]}
        onChange={onChangeServiceCategory}
      />

      <InputTextareaComponent
        number={number++}
        name={"arti_pelayanan"}
        label={translate("question_2")}
        value={artiPelayanan}
        onChange={onChangeArtiPelayanan}
      />

      <InputTextareaComponent
        number={number++}
        name={"alasan_pelayanan"}
        label={translate("question_3")}
        value={alasanPelayanan}
        onChange={onChangeAlasanPelayanan}
      />

      <InputRadioComponent
        number={number++}
        name={"pengalaman_bidang"}
        label={translate("question_4")}
        value={pengalamanBidang}
        options={[
          {
            value: "Sudah",
            description: translate("yes"),
          },
          {
            value: "Belum",
            description: translate("no"),
          },
        ]}
        onChange={onChangePengalamanBidang}
      />

      <InputRadioComponent
        number={number++}
        name={"berapa_lama_menekuni_bidang_ini"}
        label={translate("question_5")}
        value={berapaLamaMenekuniBidangIni}
        options={[
          {
            value: "1 < Tahun",
            description: translate("1_year"),
          },
          {
            value: "1 - 5 Tahun",
            description: translate("1_5_year"),
          },
          {
            value: "> 5 Tahun",
            description: translate("5_year"),
          },
        ]}
        inline={false}
        onChange={onChangeBerapaLamaMenekuniBidangIni}
      />

      <InputRadioComponent
        number={number++}
        name={"prestasi_dalam_bidang"}
        label={translate("question_6")}
        value={prestasiDalamBidang}
        options={[
          {
            value: "Ada",
            description: translate("yes"),
          },
          {
            value: "Tidak",
            description: translate("no"),
          },
        ]}
        onChange={onChangePrestasiDalamBidang}
      />

      <InputRadioComponent
        number={number++}
        name={"bersedia_meluangkan_waktu"}
        label={translate("question_7")}
        value={bersediaMeluangkanWaktu}
        options={[
          {
            value: "Ya",
            description: translate("yes"),
          },
          {
            value: "Tidak",
            description: translate("no"),
          },
        ]}
        onChange={onChangeBersediaMeluangkanWaktu}
      />

      <InputRadioComponent
        number={number++}
        name={"keluarga_mendukung"}
        label={translate("question_8")}
        value={keluargaMendukung}
        options={[
          {
            value: "Ya",
            description: translate("yes"),
          },
          {
            value: "Tidak",
            description: translate("no"),
          },
        ]}
        onChange={onChangeKeluargaMendukung}
      />

      <InputTextareaComponent
        number={number++}
        name={"dimana_belajar_bidang"}
        label={translate("question_9")}
        value={dimanaBelajarBidang}
        onChange={onChangeDimanaBelajarBidang}
      />

      <InputTextareaComponent
        number={number++}
        name={"hobby"}
        label={translate("question_10")}
        value={hobby}
        onChange={onChangeHobby}
      />

      <InputTextareaComponent
        number={number++}
        name={"aktivitas_nyata_hobby"}
        label={translate("question_11")}
        value={aktivitasNyataHobby}
        onChange={onChangeAktivitasNyataHobby}
      />

      <InputTextareaComponent
        number={number++}
        name={"kegiatan_menyita_waktu"}
        label={translate("question_12")}
        value={kegiatanMenyitaWaktu}
        onChange={onChangeKegiatanMenyitaWaktu}
      />

      <InputRadioComponent
        number={number++}
        name={"kegiatan_mempengaruhi_pelayanan"}
        label={translate("question_13")}
        value={kegiatanMempengaruhiPelayanan}
        options={[
          {
            value: "Ya",
            description: translate("yes"),
          },
          {
            value: "Tidak",
            description: translate("no"),
          },
        ]}
        onChange={onChangeKegiatanMempengaruhiPelayanan}
      />

      <InputRadioComponent
        number={number++}
        name={"pelayanan_di_gereja_lain"}
        label={translate("question_14")}
        value={pelayananDiGerejaLain}
        options={[
          {
            value: "Ya",
            description: translate("yes"),
          },
          {
            value: "Tidak",
            description: translate("no"),
          },
        ]}
        onChange={onChangePelayananDiGerejaLain}
      />

      <InputTextareaComponent
        number={number++}
        name={"apa_yang_ingin_dicapai_dalam_5_tahun"}
        label={translate("question_15")}
        value={apaYangInginDicapaiDalam5Tahun}
        onChange={onChangeApaYangInginDicapaiDalam5Tahun}
      />

      <InputTextareaComponent
        number={number++}
        name={"susunan_prioritas"}
        label={translate("question_16")}
        value={susunanPrioritas}
        onChange={onChangeSusunanPrioritas}
      />

      <InputTextareaComponent
        number={number++}
        name={"kekuatan_kelemahan"}
        label={translate("question_17")}
        value={kekuatanKelemahan}
        onChange={onChangeKekuatanKelemahan}
      />

      <InputTextareaComponent
        number={number++}
        name={"talenta"}
        label={translate("question_18")}
        value={talenta}
        onChange={onChangeTalenta}
      />

      <InputRadioComponent
        number={number++}
        name={"talenta_menjadi_motivasi"}
        label={translate("question_19")}
        value={talentaMenjadiMotivasi}
        options={[
          {
            value: "Ya",
            description: translate("yes"),
          },
          {
            value: "Tidak",
            description: translate("no"),
          },
        ]}
        onChange={onChangeTalentaMenjadiMotivasi}
      />

      <InputTextareaComponent
        number={number++}
        name={"skill_khusus"}
        label={translate("question_20")}
        value={skillKhusus}
        onChange={onChangeSkillKhusus}
      />

      <InputTextareaComponent
        number={number++}
        name={"pendidikan_terakhir"}
        label={translate("question_21")}
        value={pendidikanTerakhir}
        onChange={onChangePendidikanTerakhir}
      />

      <InputTextareaComponent
        number={number++}
        name={"value_hidup"}
        label={translate("question_22")}
        value={valueHidup}
        onChange={onChangeValueHidup}
      />

      <FormGroup>
        <div>
          <Label for="pergumulan">
            <span className="me-1">{number++}.</span>
            {translate("question_23")}
            <span className="text-danger ms-1">*</span>
          </Label>
        </div>

        <InputRadioComponent
          number={""}
          name={"pergumulan_hubungan"}
          label={translate("relationship")}
          value={pergumulanHubungan}
          options={[
            {
              value: "Ya",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePergumulanHubungan}
        />

        <InputRadioComponent
          number={""}
          name={"pergumulan_pekerjaan_studi"}
          label={translate("work_or_study")}
          value={pergumulanPekerjaanStudi}
          options={[
            {
              value: "Ya",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePergumulanPekerjaanStudi}
        />

        <InputRadioComponent
          number={""}
          name={"pergumulan_kesehatan"}
          label={translate("health")}
          value={pergumulanKesehatan}
          options={[
            {
              value: "Ya",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePergumulanKesehatan}
        />

        <InputRadioComponent
          number={""}
          name={"pergumulan_keuangan"}
          label={translate("finance")}
          value={pergumulanKeuangan}
          options={[
            {
              value: "Ya",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePergumulanKeuangan}
        />
      </FormGroup>

      <InputRadioComponent
        number={number++}
        name={"rutin_saat_teduh"}
        label={translate("question_24")}
        value={rutinSaatTeduh}
        options={[
          {
            value: "Ya",
            description: translate("yes"),
          },
          {
            value: "Tidak",
            description: translate("no"),
          },
        ]}
        onChange={onChangeRutinSaatTeduh}
      />

      <InputTextareaComponent
        number={number++}
        name={"nama_rohaniawan"}
        label={translate("question_25")}
        value={namaRohaniawan}
        onChange={onChangeNamaRohaniawan}
      />

      <FormGroup>
        <div>
          <Label for="pendapat">
            <span className="me-1">{number++}.</span>
            {translate("question_26")}{" "}
            <span className="text-danger ms-1">*</span>
          </Label>
        </div>

        <InputRadioComponent
          number={""}
          name={"pendapat_minuman_beralkohol"}
          label={translate("consume_alcoholic_drinks")}
          value={pendapatMinumanBeralkohol}
          options={[
            {
              value: "Boleh",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePendapatMinumanBeralkohol}
        />

        <InputRadioComponent
          number={""}
          name={"pendapat_merokok"}
          label={translate("smoking")}
          value={pendapatMerokok}
          options={[
            {
              value: "Boleh",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePendapatMerokok}
        />

        <InputRadioComponent
          number={""}
          name={"pendapat_perceraian"}
          label={translate("divorce_and_remarriage")}
          value={pendapatPerceraian}
          options={[
            {
              value: "Boleh",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePendapatPerceraian}
        />

        <InputRadioComponent
          number={""}
          name={"pendapat_aborsi"}
          label={translate("forced_abortion")}
          value={pendapatAborsi}
          options={[
            {
              value: "Boleh",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePendapatAborsi}
        />

        <InputRadioComponent
          number={""}
          name={"pendapat_homoseksual"}
          label={translate("homosexual")}
          value={pendapatHomoSeksual}
          options={[
            {
              value: "Boleh",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePendapatHomoseksual}
        />

        <InputRadioComponent
          number={""}
          name={"pendapat_hutang_pribadi"}
          label={translate("personal_debt")}
          value={pendapatHutangPribadi}
          options={[
            {
              value: "Boleh",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePendapatHutangPribadi}
        />

        <InputRadioComponent
          number={""}
          name={"pendapat_tatoo"}
          label={translate("tatoo")}
          value={pendapatTatoo}
          options={[
            {
              value: "Boleh",
              description: translate("yes"),
            },
            {
              value: "Tidak",
              description: translate("no"),
            },
          ]}
          onChange={onChangePendapatTatoo}
        />
      </FormGroup>

      <InputRadioComponent
        number={number++}
        name={"status"}
        label={translate("status")}
        value={status}
        options={[
          {
            value: "Single",
            description: translate("single"),
          },
          {
            value: "Married",
            description: translate("married"),
          },
          {
            value: "Others",
            description: translate("others"),
          },
        ]}
        onChange={onChangeStatus}
      />

      {status === "Married" ? (
        <InputTextareaComponent
          number={number++}
          name={"nama_pasangan"}
          label={translate("your_partners_name_if_married")}
          value={namaPasangan}
          onChange={onChangeNamaPasangan}
        />
      ) : null}

      {cookies.get("first_ministry_id") === eaglekidz_ministry_id ||
      cookies.get("second_ministry_id") === eaglekidz_ministry_id ? (
        <InputTextareaComponent
          number={number++}
          name={"ayat_favorit"}
          label={translate("your_favorite_verse")}
          value={ayatFavorit}
          onChange={onChangeAyatFavorit}
        />
      ) : null}

      {account.mygms_id}

      {cookies.get("first_ministry_id") === paw_ministry_id &&
      account.account.church.church_regional_id === church_regional_id ? (
        <FormGroup>
          <Label for="kota_domisili">{translate("city_of_domicile")}</Label>
          <CityOfDomicileComponent translate={translate} />
          {/* <AsyncSelect
          id="kota_domisili"
          options={cities}
          placeholder={translate("search") + " " + translate("city")}
          onKeyDown={(value) => onChangeCity(value)}
          defaultValue={{
            label: '',
            value: '',
          }}
        /> */}
        </FormGroup>
      ) : null}

      <AgreeComponent
        text={translate(
          "i_hereby_complete_correctly_and_agree_with_the_servolution_rules_for_the_mawar_sharon_church"
        )}
        onClick={onClickAgree}
      />

      <SubmitComponent
        disabled={submitDisabled}
        type={"submit"}
        text={translate("submit_registration")}
        icon={<FaPaperPlane className="ms-2" />}
      />
    </Form>
  );
}
