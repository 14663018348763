import React from "react";
import moment from "moment";
import Countdown from "react-countdown";
import { renderer } from "../Home/CountdownRendererComponent";

export default function CountDownComponent({ translate, event, navigate }) {
  return (
    <Countdown
      date={moment(event.closeDate).add(1, "days").format("YYYY-MM-DD")}
      renderer={(props) => renderer({ ...props, translate, event, navigate })}
    />
  );
}
