import React, { useEffect } from "react";

import { title } from "../utils/ConstantsUtil";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import HolySpiritBaptized from "../assets/images/holy-spirit-baptized.png";

export default function HolySpiritBaptizedPage({ translate, setIsLoading }) {
  const titlePage = translate("holy_spirit_baptized");
  useDocumentTitle(titlePage);

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [setIsLoading]);

  return (
    <>
      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={HolySpiritBaptized}
          alt={titlePage + " - " + title}
        />

        <h3 className="fw-bold my-3">{translate("holy_spirit_baptized")}</h3>
        <h4>{translate("sorry_you_cannot_register_on_servolution_yet")}</h4>
        <p>
          {translate(
            "you_need_to_complete_the_requirements_for_the_baptism_of_the_holy_spirit"
          )}
        </p>
        <p>
          {translate(
            "prayer_services_for_the_baptism_of_the_holy_spirit_can_be_through_the_cg_or_healing_room_leaders_on_saturday_after_the_aog_service_and_sunday_at_the_end_of_each_public_service"
          )}
        </p>
      </div>
    </>
  );
}
