import React from "react";

export default function YoutubeComponent() {
  return (
    <iframe
      src="https://www.youtube.com/embed/hhUd52qx-p4"
      className="w-100 rounded mb-3"
      height={500}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="YouTube Short"
    ></iframe>
  );
}
