import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { FaArrowLeft, FaEraser, FaPaperPlane } from "react-icons/fa";
import { Button, Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";

import moment from "moment";
import Cookies from "universal-cookie";

import CheckCookiesUtil from "../utils/CheckCookiesUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

const cookies = new Cookies();

export default function DeveloperPage({ translate, setIsLoading }) {
  const titlePage = translate("developer");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [setIsLoading]);

  const code = cookies.get("code");
  const jwt = cookies.get("jwt");
  const expired = cookies.get("expired");
  const account = cookies.get("account");
  const volunteer = cookies.get("volunteer");
  const event = cookies.get("event");
  const eventDetails = cookies.get("event_details");
  const registrationStatus = cookies.get("registration_status");
  const step = cookies.get("step");
  const i18nextLng = cookies.get("i18nextLng");
  const error = cookies.get("error");

  const onClickBackToPreviousPage = () => {
    window.scrollTo(0, 0);
    return navigate(-1);
  };

  return (
    <>
      <Card color="dark" outline>
        <CardHeader className="bg-dark text-white text-center">
          {translate("developer")}
        </CardHeader>

        <ListGroup flush>
          <ListGroupItem>
            <div className="fw-bold">{translate("jwt")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(jwt, null, 4)}</code>
              </pre>
            </div>
            <hr />
            <div>{jwt.person_id}</div>
            <div>{jwt.application}</div>
            <div>{jwt.scopes.join(", ")}</div>
            <div>{moment.unix(jwt.iat).format("LLLL")}</div>
            <div>{moment.unix(jwt.exp).format("LLLL")}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("code")}</div>
            <div>
              <pre>
                <code>{code}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("expired")}</div>
            <div>
              <pre>
                <code>{expired}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("account")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(account, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("volunteer")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(volunteer, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("event")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(event, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("event_details")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(eventDetails, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("registration_status")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(registrationStatus, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("step")}</div>
            <div>{step}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("i18nextLng")}</div>
            <div>{i18nextLng}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("error")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(error, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("cookies")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(cookies, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("local_storage")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(localStorage, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <a
        draggable="false"
        href={"https://wa.me/6282298089363?text=" + JSON.stringify(cookies)}
        target="_blank"
        rel="noreferrer"
      >
        <Button color="secondary" className="w-100 mt-4">
          {translate("send_this_data_to_developer")}
          <FaPaperPlane className="ms-2" />
        </Button>
      </a>

      <Link to="/logout">
        <Button color="danger" className="w-100 mt-4">
          <FaEraser className="me-2" />
          {translate("clear")} {translate("local_storage")} &{" "}
          {translate("cookies")}
        </Button>
      </Link>

      <Button
        color="dark"
        className="w-100 mt-4"
        onClick={onClickBackToPreviousPage}
      >
        <FaArrowLeft className="me-2" />
        {translate("back_to_previous_page")}
      </Button>
    </>
  );
}
