import { Alert } from "reactstrap";

export default function WelcomeHome({ translate, account }) {
  return (
    <Alert color="dark" fade={false}>
      <span className="text-capitalize">{translate("welcome_home")},</span>
      <span className="text-capitalize ms-1">
        {account.account.name.toLowerCase()}
      </span>
    </Alert>
  );
}
