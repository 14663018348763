import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { FaArrowRight } from "react-icons/fa";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
  UncontrolledCollapse,
} from "reactstrap";

import { title, file_url, Type } from "../utils/ConstantsUtil";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import StepUtil from "../utils/StepUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import { FaDownload } from "react-icons/fa";
import ThankYou from "../assets/images/thank-you.png";

const cookies = new Cookies();

export default function ThankYouPage({ translate, setIsLoading, account }) {
  const titlePage = translate("thank_you");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    if (cookies.get("step") !== "Thank You") {
      return navigate("/question");
    }

    setIsLoading(false);
  }, [translate, setIsLoading, navigate]);

  // FIRST
  const firstMinistryName = cookies.get("first_ministry_name");

  const firstDivisionTypeId = cookies.get("first_division_type_id");
  const firstDivisionName = cookies.get("first_division_name");
  const firstDivisionDescription = cookies.get("first_division_description");

  const firstScheduleAuditionSchedule = cookies.get(
    "first_schedule_audition_schedule"
  );
  const firstScheduleAuditionRoom = cookies.get("first_schedule_audition_room");
  const firstScheduleInterviewSchedule = cookies.get(
    "first_schedule_interview_schedule"
  );
  const firstScheduleInterviewRoom = cookies.get(
    "first_schedule_interview_room"
  );
  const firstScheduleAttachments = cookies.get("first_schedule_attachments");

  // SECOND
  const secondMinistryName = cookies.get("second_ministry_name");

  const secondDivisionTypeId = cookies.get("second_division_type_id");
  const secondDivisionName = cookies.get("second_division_name");
  const secondDivisionDescription = cookies.get("second_division_description");

  const secondScheduleAuditionSchedule = cookies.get(
    "second_schedule_audition_schedule"
  );
  const secondScheduleAuditionRoom = cookies.get(
    "second_schedule_audition_room"
  );
  const secondScheduleInterviewSchedule = cookies.get(
    "second_schedule_interview_schedule"
  );
  const secondScheduleInterviewRoom = cookies.get(
    "second_schedule_interview_room"
  );
  const secondScheduleAttachments = cookies.get("second_schedule_attachments");

  return (
    <div className="text-center">
      <img
        draggable="false"
        className="w-100"
        src={ThankYou}
        alt={titlePage + " - " + title}
      />
      <h2 className="fw-bold mt-4">{translate("registration_is_complete")}</h2>
      <h4>
        {translate(
          "thank_you_for_registering_with_servolution_and_being_part_of_us_in_serving_god_together"
        )}
      </h4>
      <p>
        {translate(
          "please_wait_for_our_push_notifications_on_the_gms_church_application_and_email"
        )}
      </p>

      <p className="my-3 fw-bold">{translate("jesus_bless_you")}</p>

      {/* <Row className="mb-3">
        <Col xs={6}>
          <Button
            className="w-100"
            color={
              parseInt(firstDivisionTypeId) === Type.Audition
                ? "primary"
                : "info"
            }
            id="first"
          >
            {firstDivisionName}
          </Button>
        </Col>

        {secondScheduleAuditionRoom ? (
          <Col xs={6}>
            <Button
              className="w-100"
              color={
                parseInt(secondDivisionTypeId) === Type.Audition
                  ? "primary"
                  : "info"
              }
              id="second"
            >
              {secondDivisionName}
            </Button>
          </Col>
        ) : null}
      </Row> */}

      {/* <UncontrolledCollapse toggler="#first" className="my-3">
        <Card
          outline
          color={
            parseInt(firstDivisionTypeId) === Type.Audition ? "primary" : "info"
          }
        >
          <CardBody>
            <CardTitle tag="h6" className="fw-bold text-center">
              {firstMinistryName}
            </CardTitle>

            <CardSubtitle className="mb-2 text-muted text-center" tag="h6">
              {firstDivisionName}
            </CardSubtitle>

            <CardText
              className="text-start"
              dangerouslySetInnerHTML={{
                __html: firstDivisionDescription,
              }}
            ></CardText>

            {firstScheduleAttachments ? (
              <>
                <hr />
                <CardText>
                  <span className="fw-bold">{translate("attachments")} :</span>
                  <a
                    draggable="false"
                    href={file_url + "/materials/" + firstScheduleAttachments}
                    download={true}
                    className="btn btn-danger btn-sm ms-2"
                  >
                    <FaDownload className="me-2" />
                    {translate("download")} {translate("attachment")}
                  </a>
                </CardText>
              </>
            ) : null}

            <hr />

            {parseInt(firstDivisionTypeId) === Type.Audition ? (
              <>
                <CardText>
                  <span className="fw-bold">
                    {translate("audition_schedule")}
                  </span>
                  <br />
                  {firstScheduleAuditionSchedule}
                </CardText>
                <CardText>
                  <span className="fw-bold">{translate("audition_room")}</span>
                  <br />
                  {firstScheduleAuditionRoom}
                </CardText>
              </>
            ) : null}

            <CardText>
              <span className="fw-bold">{translate("interview_schedule")}</span>
              <br />
              {firstScheduleInterviewSchedule}
            </CardText>
            <CardText>
              <span className="fw-bold">{translate("interview_room")}</span>
              <br />
              {firstScheduleInterviewRoom}
            </CardText>
          </CardBody>
        </Card>
      </UncontrolledCollapse> */}

      {/* {secondScheduleAuditionRoom ? (
        <UncontrolledCollapse toggler="#second" className="mt-3 mb-2">
          <Card
            outline
            color={
              parseInt(secondDivisionTypeId) === Type.Audition
                ? "primary"
                : "info"
            }
          >
            <CardBody>
              <CardTitle tag="h6" className="fw-bold text-center">
                {secondMinistryName}
              </CardTitle>

              <CardSubtitle className="mb-2 text-muted text-center" tag="h6">
                {secondDivisionName}
              </CardSubtitle>

              <CardText
                className="text-start"
                dangerouslySetInnerHTML={{
                  __html: secondDivisionDescription,
                }}
              ></CardText>

              {secondScheduleAttachments ? (
                <CardText>
                  <span className="fw-bold">{translate("attachments")} :</span>
                  <a
                    draggable="false"
                    href={file_url + "/materials/" + secondScheduleAttachments}
                    download={true}
                    className="btn btn-danger btn-sm ms-2"
                  >
                    <FaDownload className="me-2" />
                    {translate("download")} {translate("attachment")}
                  </a>
                </CardText>
              ) : null}

              {parseInt(secondDivisionTypeId) === Type.Audition ? (
                <>
                  <CardText>
                    <span className="fw-bold">
                      {translate("audition_schedule")}
                    </span>
                    <br />
                    {secondScheduleAuditionSchedule}
                  </CardText>
                  <CardText>
                    <span className="fw-bold">
                      {translate("audition_room")}
                    </span>
                    <br />
                    {secondScheduleAuditionRoom}
                  </CardText>
                </>
              ) : null}

              {parseInt(secondDivisionTypeId) === Type.Interview ? (
                <>
                  <CardText>
                    <span className="fw-bold">
                      {translate("interview_schedule")}
                    </span>
                    <br />
                    {secondScheduleInterviewSchedule}
                  </CardText>
                  <CardText>
                    <span className="fw-bold">
                      {translate("interview_room")}
                    </span>
                    <br />
                    {secondScheduleInterviewRoom}
                  </CardText>
                </>
              ) : null}
            </CardBody>
          </Card>
        </UncontrolledCollapse>
      ) : null} */}

      <Link to="/registration/status">
        <Button
          color="primary"
          className="w-100"
          onClick={() => StepUtil("Registration Status")}
        >
          {translate("check_status_and_material")}
          <FaArrowRight className="ms-2" />
        </Button>
      </Link>
    </div>
  );
}
