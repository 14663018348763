import Cookies from "universal-cookie";
import PatchPawApi from "../api/PatchPawApi";

const cookies = new Cookies();

export default async function PatchPawService({ registrant_id, want_paw_kidz, navigate }) {
  try {
    const getPaw = await PatchPawApi({ registrant_id, want_paw_kidz });

    const result = getPaw.data.result;

    cookies.set("paw", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    return result;
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("paw", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_title", "Get Paw Service", {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_description", result ?? message, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    navigate("/error");

    throw new Error(error);
  }
}
