import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function GetPawApi({ registrant_id }) {
  return await axios.get(api_url + "/paw", {
    params: {
      token: token,
      registrant_id: registrant_id,
    },
  });
}
