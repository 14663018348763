import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import SaveApprovalApi from "../api/SaveApprovalApi";

const cookies = new Cookies();

export default async function SaveApprovalService({
  translate,
  navigate,
  event_id,
  person_id,
  leader_id,
  registration_id,
  data,
}) {
  if (!data.leader_approval || !data.menjalin_hubungan_kerja_sama_di_cg) {
    return Swal.fire({
      title: translate("your_session_is_expired"),
      text: translate("please_register_approval_from_start"),
      icon: "warning",
      confirmButtonText: translate("start_approval"),
      footer: translate("mawar_sharon_church"),
      allowOutsideClick: false,
    }).then(() => {
      window.scrollTo(0, 0);

      return navigate(0);
    });
  }

  try {
    const saveApproval = await SaveApprovalApi({
      event_id: event_id,
      person_id: person_id,
      leader_id: leader_id,
      registration_id: registration_id,
      data: data,
    });

    const result = saveApproval.data.result;

    cookies.set("save_approval", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    if (result === "success") {
      cookies.remove("referrer_url", { path: "/" });

      return navigate("/registration/complete");
    }
  } catch (error) {
    cookies.remove("referrer_url", { path: "/" });

    // Because Return Response 404
    if (data.leader_approval === 'tidak') {
      return navigate("/registration/rejected");
    }

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    const footerError =
      "<a href='https://wa.me/6282298089363?text=```" +
      error.response.config.data +
      "```' target='_blank'>" +
      translate("send_error_to_developer") +
      "</a>";

    Swal.fire({
      title: error.response.status + " - " + error.response.statusText,
      text: error.message + " - " + error.response.data.result,
      icon: "error",
      confirmButtonText: translate("please_try_again"),
      footer: footerError,
      allowOutsideClick: false,
    });
  }
}
