import React from "react";
import { title } from "../../utils/ConstantsUtil";

export default function RegistrationComponent({
  titlePage,
  name,
  description,
  image,
}) {
  return (
    <div className="text-center">
      <img
        draggable="false"
        className="w-100"
        src={image}
        alt={titlePage + " - " + title}
      />
      <h3 className="fw-bold my-3">{name}</h3>
      <div>{description}</div>
    </div>
  );
}
