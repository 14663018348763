import React from "react";
import { Card, CardBody } from "reactstrap";

export default function AlertComponent({
  translate,
  days,
  hours,
  minutes,
  seconds,
}) {
  return (
    <Card color="danger" className="mb-3 text-light">
      <CardBody>
        <h4>{translate("servolution_will_end_at")}</h4>
        <span className="me-1">
          {days} {translate("days")},
        </span>
        <span className="me-1">
          {hours} {translate("hours")},
        </span>
        <span className="me-1">
          {minutes} {translate("minutes")},
        </span>
        <span className="me-1">
          {seconds} {translate("seconds")},
        </span>
        <h6 className="text-warning fw-bold mt-3">
          {translate(
            "when_the_countdown_runs_out_then_you_cannot_register_a_servolution_and_must_wait_for_the_next_servolution"
          )}
        </h6>
      </CardBody>
    </Card>
  );
}
