import React, { useEffect } from "react";

import Cookies from "universal-cookie";

import CheckCookiesUtil from "../../utils/CheckCookiesUtil";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import RegistrationComponent from "../../components/Registration/RegistrationComponent";
import LoaderComponent from "../../components/LoaderComponent";

import RegistrationClosed from "../../assets/images/registration/closed.png";

const cookies = new Cookies();

export default function ClosedPage({ translate, setIsLoading, account }) {
  const titlePage = translate("registration_closed");
  useDocumentTitle(titlePage);

  cookies.remove("referrer_url", { path: "/" });

  useEffect(() => {
    cookies.remove("referrer_url", { path: "/" });
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [translate, setIsLoading]);

  if (!account) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <RegistrationComponent
      titlePage={titlePage}
      name={translate("registration_approval_has_been_closed")}
      description={translate(
        "registration_approval_for_servolution_candidates_has_ended"
      )}
      image={RegistrationClosed}
    />
  );
}
