import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { Badge, Card, CardSubtitle, CardText, CardTitle } from "reactstrap";

import { title } from "../utils/ConstantsUtil";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import VolunteerActive from "../assets/images/volunteer-active.png";
import VolunteerActiveService from "../services/VolunteerActiveService";
import LoaderComponent from "../components/LoaderComponent";

const cookies = new Cookies();

export default function VolunteerActivePage({
  translate,
  setIsLoading,
  account,
}) {
  const titlePage = translate("volunteer_active");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const [volunteer, setVolunteer] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();
    const fetchData = async () => {
      try {
        const volunteer = await VolunteerActiveService({
          account: account,
          navigate: navigate,
        });
        setVolunteer(volunteer);
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();
    setIsLoading(false);
  }, [setIsLoading, account, navigate]);

  if (!volunteer) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <>
      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={VolunteerActive}
          alt={titlePage + " - " + title}
        />
        <h3 className="fw-bold my-3">
          {translate("you_are_already_active_as_a_volunteer")}
        </h3>
        <div>
          {translate(
            "servolution_registration_is_only_intended_for_those_who_are_not_yet_volunteers"
          )}
        </div>
        <Card body className="mt-3">
          <CardTitle tag="h5" className="text-capitalize">
            {account.account.name.toLowerCase()}
          </CardTitle>
          <CardSubtitle className="fw-bold">{volunteer.division}</CardSubtitle>
          <CardText>{volunteer.ministry}</CardText>
          <Badge color="success">{translate(volunteer.status)}</Badge>
        </Card>
      </div>
    </>
  );
}
