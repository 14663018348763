import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function AccountDetailsApi({ code }) {
  return await axios.get(api_url + "/account/details", {
    params: {
      token: token,
      code: code,
    },
  });
}
