import React from "react";

import moment from "moment";

import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";

import { file_url, title } from "../../../utils/ConstantsUtil";
import { handleImageError } from "../../../utils/ImageNotFoundUtil";

import ImageNotAvailable from "../../../assets/images/image-not-available.png";

export default function UserInformationComponent({ translate, registration }) {
  const photo = file_url + "/photos/" + registration.photo ?? ImageNotAvailable;

  return (
    <Card color="success" outline className="mt-3">
      <CardHeader className="bg-success text-white text-center">
        {translate("user_information")}
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem>
          <div className="fw-bold">{translate("nij")}</div>
          <div>{registration.nij ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("name")}</div>
          <div>{registration.name ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("birth_place")}</div>
          <div>{registration.birthPlace ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("birth_date")}</div>
          <div>{moment(registration.birthDate).format("LL") ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("gender")}</div>
          <div>
            {registration.gender === "Male"
              ? translate("man")
              : translate("woman") ?? "-"}
          </div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("email")}</div>
          <div>{registration.email ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("phone")}</div>
          <div>{registration.phone ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("status")}</div>
          <div>{translate(registration.status) ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("spouse_name")}</div>
          <div>{registration.namaPasangan ?? "-"}</div>
        </ListGroupItem>

        <ListGroupItem>
          <div className="fw-bold">{translate("photo")}</div>
          <div className="mt-2 w-100">
            <a draggable="false" href={photo} target="_blank" rel="noreferrer">
              <img
                draggable="false"
                className="w-100 img-thumbnail"
                src={photo}
                alt={translate("photo") + " - " + title}
                onError={handleImageError}
              />
            </a>
          </div>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}
