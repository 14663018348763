import React from "react";

import moment from "moment";

import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";

import { file_url, title } from "../../../utils/ConstantsUtil";
import { handleImageError } from "../../../utils/ImageNotFoundUtil";

import ImageNotAvailable from "../../../assets/images/image-not-available.png";

export default function WaterBaptismComponent({ translate, registration }) {
  const certificate =
    file_url + "/photos/" + registration.certificate ?? ImageNotAvailable;

  return (
    <>
      <Card color="info" outline className="mt-3">
        <CardHeader className="bg-info text-white text-center">
          {translate("water_baptism_information")}
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem>
            <div className="fw-bold">{translate("baptism_date")}</div>
            <div>
              {registration.baptismDate
                ? moment(registration.baptismDate).format("LL")
                : "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("baptism_place")}</div>
            <div>{registration.baptismPlace}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("father_name")}</div>
            <div>{registration.fatherName}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("mother_name")}</div>
            <div>{registration.motherName}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("baptism_type")}</div>
            <div>{registration.baptismType}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">
              {translate("baptism_verification_note")}
            </div>
            <div>
              {registration.baptismVerificationNote
                ? registration.baptismVerificationNote
                : "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{translate("certificate")}</div>
            <div className="mt-2">
              <a
                draggable="false"
                href={certificate}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  draggable="false"
                  className="w-100 img-thumbnail"
                  src={certificate}
                  alt={translate("certificate") + " - " + title}
                  onError={handleImageError}
                />
              </a>
            </div>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </>
  );
}
