import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo-text.png";
import BackToTop from "./BackToTopComponent";
import { title } from "../utils/ConstantsUtil";
import ChangeLanguageComponent from "./ChangeLanguageComponent";

export default function FooterComponent({ translate }) {
  const navigate = useNavigate();
  const [clickCount, setClickCount] = useState(0);

  const onClickDeveloperPage = () => {
    setClickCount(clickCount + 1);
    if (clickCount === 9) {
      setClickCount(0);
      window.scrollTo(0, 0);
      return navigate("/developer");
    }
  };

  return (
    <footer className="text-center pb-4">
      <BackToTop translate={translate} />

      <ChangeLanguageComponent translate={translate} />

      <div className="mb-3">
        <img
          draggable="false"
          width="250"
          src={Logo}
          alt={translate("logo") + " - " + title}
          onClick={onClickDeveloperPage}
        />
      </div>

      <div className="text-center text-muted small">
        &copy; {new Date().getFullYear()} &nbsp;
        <b>{translate("mawar_sharon_church")}</b>.
        <br />
        {translate("all_rights_reserved")}
      </div>
    </footer>
  );
}
