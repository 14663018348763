import React from "react";
import Logo from "../assets/images/logo.png";
import { title } from "../utils/ConstantsUtil";

export default function HeaderComponent({ translate }) {
  return (
    <>
      <header className="text-center pt-4">
        <img
          draggable="false"
          className="me-2"
          height="50"
          src={Logo}
          alt={translate("logo") + " - " + title}
        />

        <div className="d-inline-block align-middle">
          <h3 className="text-uppercase fw-bold text-dark mb-0">
            {process.env.REACT_APP_NAME}
          </h3>
          <h5 className="text-uppercase text-secondary mb-0 font-monospace">
            {translate("year")} {new Date().getFullYear()}
          </h5>
        </div>
      </header>
    </>
  );
}
