import axios from "axios";
import { api_url, token } from "../utils/ConstantsUtil";

export default async function RegistrationStatusApi({ person_id, event_id }) {
  return await axios.get(api_url + "/registration/status", {
    params: {
      token: token,
      person_id: person_id,
      event_id: event_id,
    },
  });
}
