import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

export default function TypeComponent({ translate, onClick, search }) {
  return (
    <div className="bg-white rounded p-1 mb-3">
      <Nav justified pills>
        <NavItem>
          <NavLink
            className="pointer"
            active={search === 0 || !search ? true : false}
            onClick={() => onClick(0)}
          >
            {translate("all")}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className="pointer"
            active={search === 1 ? true : false}
            onClick={() => onClick(1)}
          >
            {translate("audition")}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className="pointer"
            active={search === 2 ? true : false}
            onClick={() => onClick(2)}
          >
            {translate("interview")}
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
}
