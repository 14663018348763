import {
  auth_url,
  response_type,
  client_id,
  redirect_uri,
  scope,
  state,
} from "./ConstantsUtil";

export default function RedirectAuthUtil() {
  return (window.location.href =
    auth_url +
    "/oauth/authorize?response_type=" +
    response_type +
    "&client_id=" +
    client_id +
    "&redirect_uri=" +
    redirect_uri +
    "&scope=" +
    scope +
    "&state=" +
    state);
}
