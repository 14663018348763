import React from "react";
import { Button } from "reactstrap";

export default function SubmitComponent({
  disabled = false,
  color = "primary",
  outline = false,
  className = "w-100 mt-3",
  text,
  icon,
  sortBy = "left",
  onClick,
}) {
  return (
    <Button
      className={disabled ? "disabled " + className : className}
      type="submit"
      color={disabled ? "secondary" : color}
      outline={outline}
      onClick={onClick}
    >
      {sortBy === "left" ? (
        <>
          <span>{text}</span> <span>{icon}</span>
        </>
      ) : (
        <>
          <span>{icon}</span> <span>{text}</span>
        </>
      )}
    </Button>
  );
}
