import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { Alert, Card } from "reactstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import {
  paw_ministry_id,
  church_regional_id,
  ServolutionType,
  Type,
} from "../utils/ConstantsUtil";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import StepUtil from "../utils/StepUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import CheckRegistrationStatusService from "../services/CheckRegistrationStatusService";
import CheckVolunteerService from "../services/CheckVolunteerService";
import EventDetailsService from "../services/EventDetailsService";
import EventService from "../services/EventService";

import ButtonComponent from "../components/ButtonComponent";
import DivisionComponent from "../components/Ministry/DivisionComponent";
import InputRadioComponent from "../components/InputRadioComponent";
import LoaderComponent from "../components/LoaderComponent";
import MinistryComponent from "../components/Ministry/MinistryComponent";
import ScheduleComponent from "../components/Ministry/ScheduleComponent";
import SearchComponent from "../components/Ministry/SearchComponent";
import TypeComponent from "../components/Ministry/TypeComponent";
import ViewComponent from "../components/Ministry/ViewComponent";
import YoutubeComponent from "../components/Ministry/YoutubeComponent";

const cookies = new Cookies();

export default function MinistryPage({ translate, setIsLoading, account }) {
  const titlePage = translate("ministry");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const [event, setEvent] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);

  // Start Search Type
  const [searchType, setSearchType] = useState(
    parseInt(cookies.get("search_type"))
  );

  const onClickSearchType = (value) => {
    setSearchType(value);
    cookies.set("search_type", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };
  // End Search Type

  // Start Search
  const [search, setSearch] = useState(cookies.get("search") ?? "");

  const onChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    cookies.set("search", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };
  // End Search

  // Start For Save Registration
  const [firstOptionMinistry, setFirstOptionMinistry] = useState(
    cookies.get("first_option_ministry")
  );

  const [firstOptionMinistryScheduleId, setFirstOptionMinistryScheduleId] =
    useState(cookies.get("first_option_ministry_schedule_id"));

  const [secondOptionMinistry, setSecondOptionMinistry] = useState(
    cookies.get("second_option_ministry")
  );

  const [secondOptionMinistryScheduleId, setSecondOptionMinistryScheduleId] =
    useState(cookies.get("second_option_ministry_schedule_id"));
  // End For Save Registration

  // Start First Ministry
  const [firstMinistryTypeId, setFirstMinistryTypeId] = useState(
    cookies.get("first_ministry_type_id")
  );

  const [firstMinistryId, setFirstMinistryId] = useState(
    cookies.get("first_ministry_id")
  );

  const [firstMinistryName, setFirstMinistryName] = useState(
    cookies.get("first_ministry_name")
  );

  const onClickFirstMinistry = (ministryTypeId, ministryId, ministryName) => {
    window.scrollTo(0, 0);

    setSearchType(Type.All);
    setSearch("");

    setFirstMinistryTypeId(ministryTypeId);
    setFirstMinistryId(ministryId);
    setFirstMinistryName(ministryName);

    cookies.set("first_ministry_type_id", ministryTypeId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_ministry_id", ministryId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_ministry_name", ministryName, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onClickBackToFirstMinistry = () => {
    setFirstMinistryTypeId(null);
    setFirstMinistryId(null);
    setFirstMinistryName(null);

    cookies.remove("first_ministry_type_id");
    cookies.remove("first_ministry_id");
    cookies.remove("first_ministry_name");
  };
  // End First Ministry

  // Start First Division
  const [firstDivisionTypeId, setFirstDivisionTypeId] = useState(
    cookies.get("first_division_type_id")
  );

  const [firstDivisionId, setFirstDivisionId] = useState(
    cookies.get("first_division_id")
  );

  const [firstDivisionName, setFirstDivisionName] = useState(
    cookies.get("first_division_name")
  );

  const [firstDivisionDescription, setFirstDivisionDescription] = useState(
    cookies.get("first_division_description")
  );

  const onClickFirstDivision = (
    divisionTypeId,
    divisionId,
    divisionName,
    divisionDescription
  ) => {
    window.scrollTo(0, 0);

    setSearchType(Type.All);
    setSearch("");

    setFirstDivisionTypeId(divisionTypeId);
    setFirstDivisionId(divisionId);
    setFirstDivisionName(divisionName);
    setFirstDivisionDescription(divisionDescription);
    setFirstOptionMinistry(divisionId);

    cookies.set("first_division_type_id", divisionTypeId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_division_id", divisionId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_division_name", divisionName, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_division_description", divisionDescription, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_option_ministry", divisionId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onClickBackToFirstDivision = () => {
    setFirstDivisionTypeId(null);
    setFirstDivisionId(null);
    setFirstDivisionName(null);
    setFirstDivisionDescription(null);
    setFirstOptionMinistry(null);

    cookies.remove("first_division_type_id");
    cookies.remove("first_division_id");
    cookies.remove("first_division_name");
    cookies.remove("first_division_description");
    cookies.remove("first_option_ministry");
  };
  // End First Division

  // Start First Schedule
  const [firstScheduleId, setFirstScheduleId] = useState(
    cookies.get("first_schedule_id")
  );

  const [firstScheduleAuditionRoom, setFirstScheduleAuditionRoom] = useState(
    cookies.get("first_schedule_audition_room")
  );

  const [firstScheduleAuditionSchedule, setFirstScheduleAuditionSchedule] =
    useState(cookies.get("first_schedule_audition_schedule"));

  const [firstScheduleInterviewRoom, setFirstScheduleInterviewRoom] = useState(
    cookies.get("first_schedule_interview_room")
  );

  const [firstScheduleInterviewSchedule, setFirstScheduleInterviewSchedule] =
    useState(cookies.get("first_schedule_interview_schedule"));

  const [firstScheduleDescription, setFirstScheduleDescription] = useState(
    cookies.get("first_schedule_description")
  );

  const [firstScheduleAttachments, setFirstScheduleAttachments] = useState(
    cookies.get("first_schedule_attachments")
  );

  const onClickFirstSchedule = (
    scheduleId,
    auditionRoom,
    auditionSchedule,
    interviewRoom,
    interviewSchedule,
    description,
    attachments
  ) => {
    window.scrollTo(0, 0);

    setSearchType(Type.All);
    setSearch("");

    setFirstScheduleId(scheduleId);
    setFirstScheduleAuditionRoom(auditionRoom);
    setFirstScheduleAuditionSchedule(auditionSchedule);
    setFirstScheduleInterviewRoom(interviewRoom);
    setFirstScheduleInterviewSchedule(interviewSchedule);
    setFirstScheduleDescription(description);
    setFirstScheduleAttachments(attachments);
    setFirstOptionMinistryScheduleId(scheduleId);

    cookies.remove("search_type");
    cookies.remove("search");

    cookies.set("first_schedule_id", scheduleId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_schedule_audition_room", auditionRoom, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_schedule_audition_schedule", auditionSchedule, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_schedule_interview_room", interviewRoom, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_schedule_interview_schedule", interviewSchedule, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_schedule_attachments", attachments, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("first_option_ministry_schedule_id", scheduleId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };
  // End First Schedule

  // Start Second Ministry
  const [secondMinistryTypeId, setSecondMinistryTypeId] = useState(
    cookies.get("second_ministry_type_id")
  );

  const [secondMinistryId, setSecondMinistryId] = useState(
    cookies.get("second_ministry_id")
  );

  const [secondMinistryName, setSecondMinistryName] = useState(
    cookies.get("second_ministry_name")
  );

  const onClickSecondMinistry = (ministryTypeId, ministryId, ministryName) => {
    window.scrollTo(0, 0);

    setSearchType(Type.All);
    setSearch("");

    setSecondMinistryTypeId(ministryTypeId);
    setSecondMinistryId(ministryId);
    setSecondMinistryName(ministryName);

    cookies.set("second_ministry_type_id", ministryTypeId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_ministry_id", ministryId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_ministry_name", ministryName, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onClickBackToSecondMinistry = () => {
    setSecondMinistryTypeId(null);
    setSecondMinistryId(null);
    setSecondMinistryName(null);

    cookies.remove("second_ministry_type_id");
    cookies.remove("second_ministry_id");
    cookies.remove("second_ministry_name");
  };
  // End Second Ministry

  // Start Second Division
  const [secondDivisionTypeId, setSecondDivisionTypeId] = useState(
    cookies.get("second_division_type_id")
  );

  const [secondDivisionId, setSecondDivisionId] = useState(
    cookies.get("second_division_id")
  );

  const [secondDivisionName, setSecondDivisionName] = useState(
    cookies.get("second_division_name")
  );

  const [secondDivisionDescription, setSecondDivisionDescription] = useState(
    cookies.get("second_division_description")
  );

  const onClickSecondDivision = (
    divisionTypeId,
    divisionId,
    divisionName,
    divisionDescription
  ) => {
    window.scrollTo(0, 0);

    setSearchType(Type.All);
    setSearch("");

    setSecondDivisionTypeId(divisionTypeId);
    setSecondDivisionId(divisionId);
    setSecondDivisionName(divisionName);
    setSecondDivisionDescription(divisionDescription);
    setSecondOptionMinistry(divisionId);

    cookies.set("second_division_type_id", divisionTypeId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_division_id", divisionId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_division_name", divisionName, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_division_description", divisionDescription, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_option_ministry", divisionId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onClickBackToSecondDivision = () => {
    setSecondDivisionTypeId(null);
    setSecondDivisionId(null);
    setSecondDivisionName(null);
    setSecondDivisionDescription(null);
    setSecondOptionMinistry(null);

    cookies.remove("second_division_type_id");
    cookies.remove("second_division_id");
    cookies.remove("second_division_name");
    cookies.remove("second_division_description");
    cookies.remove("second_option_ministry");
  };
  // End Second Division

  // Start Second Schedule
  const [secondScheduleId, setSecondScheduleId] = useState(
    cookies.get("second_schedule_id")
  );

  const [secondScheduleAuditionRoom, setSecondScheduleAuditionRoom] = useState(
    cookies.get("second_schedule_audition_room")
  );

  const [secondScheduleAuditionSchedule, setSecondScheduleAuditionSchedule] =
    useState(cookies.get("second_schedule_audition_schedule"));

  const [secondScheduleInterviewRoom, setSecondScheduleInterviewRoom] =
    useState(cookies.get("second_schedule_interview_room"));

  const [secondScheduleInterviewSchedule, setSecondScheduleInterviewSchedule] =
    useState(cookies.get("second_schedule_interview_schedule"));

  const [secondScheduleDescription, setSecondScheduleDescription] = useState(
    cookies.get("second_schedule_description")
  );

  const [secondScheduleAttachments, setSecondScheduleAttachments] = useState(
    cookies.get("second_schedule_attachments")
  );

  const onClickSecondSchedule = (
    scheduleId,
    auditionRoom,
    auditionSchedule,
    interviewRoom,
    interviewSchedule,
    description,
    attachments
  ) => {
    window.scrollTo(0, 0);

    setSearchType(Type.All);
    setSearch("");

    setSecondScheduleId(scheduleId);
    setSecondScheduleAuditionRoom(auditionRoom);
    setSecondScheduleAuditionSchedule(auditionSchedule);
    setSecondScheduleInterviewRoom(interviewRoom);
    setSecondScheduleInterviewSchedule(interviewSchedule);
    setSecondScheduleDescription(description);
    setSecondScheduleAttachments(attachments);
    setSecondOptionMinistryScheduleId(scheduleId);

    cookies.remove("search_type");
    cookies.remove("search");

    cookies.set("second_schedule_id", scheduleId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_schedule_audition_room", auditionRoom, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_schedule_audition_schedule", auditionSchedule, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_schedule_interview_room", interviewRoom, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_schedule_interview_schedule", interviewSchedule, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_schedule_description", description, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_schedule_attachments", attachments, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("second_option_ministry_schedule_id", scheduleId, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };
  // End Second Schedule

  // Start Option
  const [option, setOption] = useState(cookies.get("option") ?? "Tidak");

  const onChangeOption = (e) => {
    const value = e.target.value;
    setOption(value);
    cookies.set("option", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };
  // End Option

  // Start Want PAW Kidz
  const [wantPawKidz, setWantPawKidz] = useState(
    cookies.get("want_paw_kidz") ?? "0"
  );

  const onChangeWantPawKidz = (e) => {
    const value = e.target.value;
    setWantPawKidz(value);
    cookies.set("want_paw_kidz", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };
  // End Want PAW Kidz

  const onClickRemoveMinistry = () => {
    window.scrollTo(0, 0);

    setFirstMinistryTypeId(null);
    setFirstMinistryId(null);
    setFirstMinistryName(null);
    setFirstDivisionId(null);
    setFirstDivisionName(null);
    setFirstDivisionDescription(null);
    setFirstScheduleId(null);
    setFirstScheduleAuditionRoom(null);
    setFirstScheduleAuditionSchedule(null);
    setFirstScheduleInterviewRoom(null);
    setFirstScheduleInterviewSchedule(null);
    setFirstScheduleDescription(null);
    setFirstScheduleAttachments(null);
    setFirstOptionMinistry(null);
    setFirstOptionMinistryScheduleId(null);

    setSecondMinistryTypeId(null);
    setSecondMinistryId(null);
    setSecondMinistryName(null);
    setSecondDivisionId(null);
    setSecondDivisionName(null);
    setSecondDivisionDescription(null);
    setSecondScheduleId(null);
    setSecondScheduleAuditionRoom(null);
    setSecondScheduleAuditionSchedule(null);
    setSecondScheduleInterviewRoom(null);
    setSecondScheduleInterviewSchedule(null);
    setSecondScheduleDescription(null);
    setSecondScheduleAttachments(null);
    setSecondOptionMinistry(null);
    setSecondOptionMinistryScheduleId(null);

    cookies.remove("search_type");
    cookies.remove("search");

    cookies.remove("first_ministry_type_id");
    cookies.remove("first_ministry_id");
    cookies.remove("first_ministry_name");
    cookies.remove("first_division_type_id");
    cookies.remove("first_division_id");
    cookies.remove("first_division_name");
    cookies.remove("first_division_description");
    cookies.remove("first_schedule_id");
    cookies.remove("first_schedule_audition_room");
    cookies.remove("first_schedule_audition_schedule");
    cookies.remove("first_schedule_interview_room");
    cookies.remove("first_schedule_interview_schedule");
    cookies.remove("first_schedule_attachments");
    cookies.remove("first_option_ministry");
    cookies.remove("first_option_ministry_schedule_id");

    cookies.remove("second_ministry_type_id");
    cookies.remove("second_ministry_id");
    cookies.remove("second_ministry_name");
    cookies.remove("second_division_type_id");
    cookies.remove("second_division_id");
    cookies.remove("second_division_name");
    cookies.remove("second_division_description");
    cookies.remove("second_schedule_id");
    cookies.remove("second_schedule_audition_room");
    cookies.remove("second_schedule_audition_schedule");
    cookies.remove("second_schedule_interview_room");
    cookies.remove("second_schedule_interview_schedule");
    cookies.remove("second_schedule_attachments");
    cookies.remove("second_option_ministry");
    cookies.remove("second_option_ministry_schedule_id");

    cookies.remove("want_paw_kidz");
  };

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    if (cookies.get("step") !== "Ministry") {
      if (cookies.get("certificate")) {
        return navigate("/water-baptism");
      } else {
        return navigate("/profile");
      }
    }

    const fetchData = async () => {
      try {
        await CheckVolunteerService({ account: account, navigate: navigate });

        const event = await EventService({
          account: account,
          navigate: navigate,
        });
        setEvent(event);

        const eventDetails = await EventDetailsService({
          event: event,
          navigate: navigate,
        });
        setEventDetails(eventDetails);

        await CheckRegistrationStatusService({
          account: account,
          event: event,
          navigate: navigate,
        });
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();

    setIsLoading(false);
  }, [translate, setIsLoading, navigate, account]);

  if (!event) {
    return <LoaderComponent translate={translate} />;
  }

  if (!eventDetails) {
    return <LoaderComponent translate={translate} />;
  }

  let disabled = true;

  const servolutionType = event.firstOptionSecondOptionType;
  // const servolutionType = ServolutionType.FirstOption; // TESTING
  // const servolutionType = ServolutionType.Optional; // TESTING
  // const servolutionType = ServolutionType.SecondOption; // TESTING

  if (servolutionType === ServolutionType.FirstOption) {
    if (firstOptionMinistry && firstOptionMinistryScheduleId) {
      disabled = false;
    }
  }

  if (servolutionType === ServolutionType.Optional) {
    if (parseInt(firstDivisionTypeId) === Type.Audition) {
      if (option === "Ya") {
        if (
          firstOptionMinistry &&
          firstOptionMinistryScheduleId &&
          secondOptionMinistry &&
          secondOptionMinistryScheduleId
        ) {
          disabled = false;
        }
      } else if (option === "Tidak") {
        if (firstOptionMinistry && firstOptionMinistryScheduleId) {
          disabled = false;
        }
      }
    } else if (
      parseInt(firstDivisionTypeId) === Type.Interview &&
      firstOptionMinistryScheduleId
    ) {
      disabled = false;
    }
  }

  if (servolutionType === ServolutionType.SecondOption) {
    if (
      (parseInt(firstDivisionTypeId) === Type.Interview &&
        firstOptionMinistry &&
        firstOptionMinistryScheduleId) ||
      (parseInt(firstDivisionTypeId) === Type.Audition &&
        firstOptionMinistry &&
        firstOptionMinistryScheduleId &&
        secondOptionMinistry &&
        secondOptionMinistryScheduleId)
    ) {
      disabled = false;
    }
  }

  return (
    <>
      <TypeComponent
        translate={translate}
        onClick={onClickSearchType}
        search={searchType}
      />

      <SearchComponent
        translate={translate}
        onChangeSearch={onChangeSearch}
        search={search}
        name={"ministry"}
      />

      {servolutionType === ServolutionType.SecondOption &&
      parseInt(firstDivisionTypeId) === Type.Audition &&
      firstMinistryId &&
      firstDivisionId &&
      firstScheduleId &&
      !secondMinistryId &&
      !secondDivisionId &&
      !secondScheduleId ? (
        <Alert color="warning" fade={false}>
          <h5 className="fw-bold text-center text-uppercase">
            {translate("attention")}!
          </h5>
          <div>
            {translate(
              "you_must_choose_the_second_division_because_you_have_already_chosen"
            )}
          </div>
          <div>
            {translate("division")} : <strong>{firstDivisionName}</strong>
          </div>
          <div>
            {translate("ministry")} : <strong>{firstMinistryName}</strong>
          </div>
        </Alert>
      ) : null}

      {firstMinistryId && !firstDivisionId ? (
        <ButtonComponent
          color={"light"}
          className={"w-100 mb-3"}
          text={
            translate("back_to") +
            " " +
            translate("list") +
            " " +
            translate("ministry")
          }
          icon={<FaArrowLeft className="me-2" />}
          sortBy={"right"}
          onClick={onClickBackToFirstMinistry}
        />
      ) : null}

      {firstDivisionId && !firstScheduleId ? (
        <ButtonComponent
          color={"light"}
          className={"w-100 mb-3"}
          text={
            translate("back_to") +
            " " +
            translate("list") +
            " " +
            translate("division")
          }
          icon={<FaArrowLeft className="me-2" />}
          sortBy={"right"}
          onClick={onClickBackToFirstDivision}
        />
      ) : null}

      {secondMinistryId && !secondDivisionId ? (
        <ButtonComponent
          color={"light"}
          className={"w-100 mb-3"}
          text={
            translate("back_to") +
            " " +
            translate("list") +
            " " +
            translate("ministry")
          }
          icon={<FaArrowLeft className="me-2" />}
          sortBy={"right"}
          onClick={onClickBackToSecondMinistry}
        />
      ) : null}

      {secondDivisionId && !secondScheduleId ? (
        <ButtonComponent
          color={"light"}
          className={"w-100 mb-3"}
          text={
            translate("back_to") +
            " " +
            translate("list") +
            " " +
            translate("division")
          }
          icon={<FaArrowLeft className="me-2" />}
          sortBy={"right"}
          onClick={onClickBackToSecondDivision}
        />
      ) : null}

      {!firstMinistryId && !firstDivisionId ? (
        <MinistryComponent
          translate={translate}
          event={event}
          eventDetails={eventDetails}
          search={search}
          searchType={searchType}
          step={1}
          type={Type}
          onClick={onClickFirstMinistry}
        />
      ) : null}

      {firstMinistryId && !firstDivisionId ? (
        <DivisionComponent
          translate={translate}
          event={event}
          eventDetails={eventDetails}
          search={search}
          searchType={searchType}
          ministryTypeId={firstMinistryTypeId}
          ministryId={firstMinistryId}
          ministryName={firstMinistryName}
          step={1}
          type={Type}
          onClick={onClickFirstDivision}
        />
      ) : null}

      {firstMinistryId && firstDivisionId && !firstScheduleId ? (
        <ScheduleComponent
          account={account}
          translate={translate}
          event={event}
          eventDetails={eventDetails}
          ministryId={firstMinistryId}
          divisionTypeId={firstDivisionTypeId}
          divisionId={firstDivisionId}
          divisionName={firstDivisionName}
          step={1}
          type={Type}
          onClick={onClickFirstSchedule}
        />
      ) : null}

      {servolutionType === ServolutionType.Optional &&
      parseInt(firstDivisionTypeId) === Type.Audition &&
      firstMinistryId &&
      firstDivisionId &&
      firstScheduleId &&
      !secondMinistryId &&
      !secondDivisionId &&
      !secondScheduleId ? (
        <Card body color="primary" outline className="mb-3">
          <InputRadioComponent
            number={""}
            name={"option"}
            label={
              translate(
                "do_you_want_to_choose_another_field_of_service_if_you_dont_pass_in_the_first_field_of_service"
              ) + " ?"
            }
            value={option}
            options={[
              {
                value: "Ya",
                description: translate("yes"),
              },
              {
                value: "Tidak",
                description: translate("no"),
              },
            ]}
            onChange={onChangeOption}
          />
        </Card>
      ) : null}

      {/* PAW KIDZ */}
      {firstScheduleId &&
      firstMinistryId === paw_ministry_id &&
      firstDivisionName.includes("Kidz") !== true &&
      account.account.church.church_regional_id === church_regional_id &&
      firstMinistryId &&
      firstDivisionId &&
      firstScheduleId &&
      !secondMinistryId &&
      !secondDivisionId &&
      !secondScheduleId ? (
        <Card body color="primary" outline className="mb-3">
          <YoutubeComponent />

          <InputRadioComponent
            noMargin={true}
            number={""}
            name={"want_paw_kidz"}
            label={
              translate(
                "if_you_do_not_pass_the_praise_and_worship_adutinio_phase_are_you_willing_to_serve_on_the_eaglekidz_paw_team"
              ) + " ?"
            }
            value={wantPawKidz}
            options={[
              {
                value: "1",
                description: translate("yes"),
              },
              {
                value: "0",
                description: translate("no"),
              },
            ]}
            onChange={onChangeWantPawKidz}
          />
        </Card>
      ) : null}
      {/* PAW KIDZ */}

      {(servolutionType === ServolutionType.SecondOption &&
        parseInt(firstDivisionTypeId) === Type.Audition) ||
      (servolutionType === ServolutionType.Optional &&
        option === "Ya" &&
        parseInt(firstDivisionTypeId) === Type.Audition) ? (
        <>
          {firstScheduleId && !secondMinistryId ? (
            <MinistryComponent
              translate={translate}
              event={event}
              eventDetails={eventDetails}
              search={search}
              searchType={searchType}
              step={2}
              type={Type}
              onClick={onClickSecondMinistry}
            />
          ) : null}

          {firstScheduleId && secondMinistryId && !secondDivisionId ? (
            <DivisionComponent
              translate={translate}
              event={event}
              eventDetails={eventDetails}
              search={search}
              searchType={searchType}
              ministryTypeId={secondMinistryTypeId}
              ministryId={secondMinistryId}
              ministryName={secondMinistryName}
              firstDivisionId={firstDivisionId}
              step={2}
              type={Type}
              onClick={onClickSecondDivision}
            />
          ) : null}

          {firstScheduleId &&
          secondMinistryId &&
          secondDivisionId &&
          !secondScheduleId ? (
            <ScheduleComponent
              account={account}
              translate={translate}
              event={event}
              eventDetails={eventDetails}
              ministryId={secondMinistryId}
              divisionTypeId={secondDivisionTypeId}
              divisionId={secondDivisionId}
              divisionName={secondDivisionName}
              step={2}
              type={Type}
              onClick={onClickSecondSchedule}
            />
          ) : null}
        </>
      ) : null}

      {firstMinistryId && firstDivisionId && firstScheduleId ? (
        <ViewComponent
          translate={translate}
          firstMinistryName={firstMinistryName}
          firstDivisionTypeId={firstDivisionTypeId}
          firstDivisionName={firstDivisionName}
          firstDivisionDescription={firstDivisionDescription}
          firstScheduleAuditionRoom={firstScheduleAuditionRoom}
          firstScheduleAuditionSchedule={firstScheduleAuditionSchedule}
          firstScheduleInterviewRoom={firstScheduleInterviewRoom}
          firstScheduleInterviewSchedule={firstScheduleInterviewSchedule}
          firstScheduleDescription={firstScheduleDescription}
          firstScheduleAttachments={firstScheduleAttachments}
          secondMinistryName={secondMinistryName}
          secondDivisionTypeId={secondDivisionTypeId}
          secondDivisionName={secondDivisionName}
          secondDivisionDescription={secondDivisionDescription}
          secondScheduleAuditionRoom={secondScheduleAuditionRoom}
          secondScheduleAuditionSchedule={secondScheduleAuditionSchedule}
          secondScheduleInterviewRoom={secondScheduleInterviewRoom}
          secondScheduleInterviewSchedule={secondScheduleInterviewSchedule}
          secondScheduleDescription={secondScheduleDescription}
          secondScheduleAttachments={secondScheduleAttachments}
          type={Type}
          onClick={onClickRemoveMinistry}
        />
      ) : null}

      <ButtonComponent
        disabled={disabled}
        link={"/question"}
        className={"w-100 mt-2"}
        text={translate("save_and_continue")}
        icon={<FaArrowRight className="ms-2" />}
        onClick={() => StepUtil("Question")}
      />

      {cookies.get("baptism_date") ? (
        <ButtonComponent
          link={"/water-baptism"}
          color={"light"}
          text={translate("back_to") + " " + translate("water_baptism")}
          icon={<FaArrowLeft className="me-2" />}
          sortBy={"right"}
          onClick={() => StepUtil("Water Baptism")}
        />
      ) : (
        <ButtonComponent
          link={"/profile"}
          color={"light"}
          text={translate("back_to") + " " + translate("profile")}
          icon={<FaArrowLeft className="me-2" />}
          sortBy={"right"}
          onClick={() => StepUtil("Profile")}
        />
      )}
    </>
  );
}
