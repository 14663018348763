import React, { useEffect } from "react";

import Cookies from "universal-cookie";

import CheckCookiesUtil from "../../utils/CheckCookiesUtil";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import RegistrationComponent from "../../components/Registration/RegistrationComponent";
import LoaderComponent from "../../components/LoaderComponent";

import RegistrationComplete from "../../assets/images/registration/complete.png";

const cookies = new Cookies();

export default function CompletePage({ translate, setIsLoading, account }) {
  const titlePage = translate("registration_completed");
  useDocumentTitle(titlePage);

  cookies.remove("referrer_url", { path: "/" });

  useEffect(() => {
    cookies.remove("referrer_url", { path: "/" });
    setIsLoading(true);
    CheckCookiesUtil();
    setIsLoading(false);
  }, [translate, setIsLoading]);

  if (!account) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <RegistrationComponent
      titlePage={titlePage}
      name={translate("registration_approval_has_been_completed")}
      description={translate(
        "thank_you_for_filling_out_the_registration_approval_form"
      )}
      image={RegistrationComplete}
    />
  );
}
