import Cookies from "universal-cookie";
import RedirectAuthUtil from "../utils/RedirectAuthUtil";

const cookies = new Cookies();

export default function LogoutService() {
  localStorage.clear();

  const allCookieNames = cookies.getAll();

  Object.keys(allCookieNames).forEach((cookieName) => {
    cookies.remove(cookieName, {
      path: "/",
    });
  });

  RedirectAuthUtil();
}
