import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { Card, CardHeader, CardBody, CardFooter, Form } from "reactstrap";
import { FaSave } from "react-icons/fa";

import SaveApprovalService from "../../../services/SaveApprovalService";

import AgreeComponent from "../../AgreeComponent";
import InputRadioComponent from "./../../InputRadioComponent";
import InputTextareaComponent from "./../../InputTextareaComponent";
import SubmitComponent from "../../SubmitComponent";

const cookies = new Cookies();

export default function FormComponent({
  translate,
  registration,
  event_id,
  person_id,
  leader_id,
}) {
  const navigate = useNavigate();

  let number = 1;
  let disabled = true;

  const [leaderApproval, setLeaderApproval] = useState(
    cookies.get("leader_approval") ?? ""
  );

  const [menjalinHubunganKerjaSamaDiCg, setMenjalinHubunganKerjaSamaDiCg] =
    useState(cookies.get("menjalin_hubungan_kerja_sama_di_cg") ?? "");

  const [leaderNote, setLeaderNote] = useState(
    cookies.get("leader_note") ?? ""
  );

  const [agree, setAgree] = useState(false);

  const onChangeLeaderApproval = (e) => {
    const value = e.target.value;
    setLeaderApproval(value);
    cookies.set("leader_approval", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeMenjalinHubunganKerjaSamaDiCg = (e) => {
    const value = e.target.value;
    setMenjalinHubunganKerjaSamaDiCg(value);
    cookies.set("menjalin_hubungan_kerja_sama_di_cg", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onChangeLeaderNote = (e) => {
    const value = e.target.value;
    setLeaderNote(value);
    cookies.set("leader_note", value, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  const onClickAgree = (e) => {
    const checked = e.target.checked;
    setAgree(checked);
    cookies.set("agree", checked, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });
  };

  if (leaderApproval && menjalinHubunganKerjaSamaDiCg && agree) {
    disabled = false;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = {
      leader_approval: cookies.get("leader_approval"),
      menjalin_hubungan_kerja_sama_di_cg: cookies.get(
        "menjalin_hubungan_kerja_sama_di_cg"
      ),
      leader_note: cookies.get("leader_note"),
    };

    const registration_id = registration.id;

    await SaveApprovalService({
      translate,
      navigate,
      event_id,
      person_id,
      leader_id,
      registration_id,
      data,
    });
  };

  return (
    <>
      <Card color="dark" outline className="mt-3">
        <CardHeader className="bg-dark text-white text-center">
          {translate("form")} {translate("registration_approval")}
        </CardHeader>

        <CardBody>
          <Form onSubmit={onSubmit}>
            <InputRadioComponent
              number={number++}
              name={"leader_approval"}
              label={translate("status_servolution_candidate") + " ?"}
              value={leaderApproval}
              options={[
                {
                  value: "ya",
                  description: translate("approve"),
                },
                {
                  value: "tidak",
                  description: translate("reject"),
                },
              ]}
              onChange={onChangeLeaderApproval}
            />

            <InputRadioComponent
              number={number++}
              name={"menjalin_hubungan_kerja_sama_di_cg"}
              label={
                translate(
                  "can_registrants_establish_good_cooperative_relations_with_cgl_or_sponsors_and_cg_members"
                ) + " ?"
              }
              value={menjalinHubunganKerjaSamaDiCg}
              options={[
                {
                  value: "Ya",
                  description: translate("yes"),
                },
                {
                  value: "Tidak",
                  description: translate("no"),
                },
              ]}
              onChange={onChangeMenjalinHubunganKerjaSamaDiCg}
            />

            <InputTextareaComponent
              number={number++}
              name={"leader_note"}
              label={translate("note")}
              value={leaderNote}
              required={false}
              onChange={onChangeLeaderNote}
            />

            <AgreeComponent
              text={translate(
                "i_hereby_have_read_the_information_of_potential_participants_servolution_correctly_and_wisely_answer_accordingly_servolution_rules_that_apply_from_the_mawar_sharon_church"
              )}
              onClick={onClickAgree}
            />

            <SubmitComponent
              disabled={disabled}
              link={"/ministry"}
              sortBy={"right"}
              text={translate("save_registration_approval")}
              icon={<FaSave className="me-1" />}
            />
          </Form>
        </CardBody>
        <CardFooter className="text-center">
          {translate("form")} {translate("registration_approval")}
        </CardFooter>
      </Card>
    </>
  );
}
