import React from "react";
import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";

export default function QuestionComponent({ translate, registration }) {
  return (
    <>
      <Card color="danger" outline className="mt-3">
        <CardHeader className="bg-danger text-white text-center">
          {translate("question_information")}
        </CardHeader>
        <ListGroup flush numbered>
          <ListGroupItem className="fw-bold">
            <span>{translate("question_2")}</span>
            <div className="fw-light">{registration.artiPelayanan ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_3")}</span>
            <div className="fw-light">
              {registration.alasanPelayanan ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_4")}</span>
            <div className="fw-light">
              {registration.pengalamanBidang ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_5")}</span>
            <div className="fw-light">
              {registration.berapaLamaMenekuniBidangIni ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_6")}</span>
            <div className="fw-light">
              {registration.prestasiDalamBidang ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_7")}</span>
            <div className="fw-light">
              {registration.bersediaMeluangkanWaktu ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_8")}</span>
            <div className="fw-light">
              {registration.keluargaMendukung ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_9")}</span>
            <div className="fw-light">
              {registration.dimanaBelajarBidang ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_10")}</span>
            <div className="fw-light">{registration.hobby ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_11")}</span>
            <div className="fw-light">
              {registration.aktivitasNyataHobby ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_12")}</span>
            <div className="fw-light">
              {registration.kegiatanMenyitaWaktu ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_13")}</span>
            <div className="fw-light">
              {registration.kegiatanMempengaruhiPelayanan ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_14")}</span>
            <div className="fw-light">
              {registration.pelayananDiGerejaLain ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_15")}</span>
            <div className="fw-light">
              {registration.apaYangInginDicapaiDalam_5Tahun ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_16")}</span>
            <div className="fw-light">
              {registration.susunanPrioritas ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_17")}</span>
            <div className="fw-light">
              {registration.kekuatanKelemahan ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_18")}</span>
            <div className="fw-light">{registration.talenta ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_19")}</span>
            <div className="fw-light">
              {registration.talentaMenjadiMotivasi ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_20")}</span>
            <div className="fw-light">{registration.skillKhusus ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_21")}</span>
            <div className="fw-light">
              {registration.pendidikanTerakhir ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_22")}</span>
            <div className="fw-light">{registration.valueHidup ?? "-"}</div>
          </ListGroupItem>

          <span>{translate("question_23")}</span>

          <ListGroupItem className="fw-bold">
            <span>{translate("relationship")}</span>
            <div className="fw-light">
              {registration.pergumulanHubungan ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("work_or_study")}</span>
            <div className="fw-light">
              {registration.pergumulanPekerjaanStudi ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("health")}</span>
            <div className="fw-light">
              {registration.pergumulanKesehatan ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("finance")}</span>
            <div className="fw-light">
              {registration.pergumulanKeuangan ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_24")}</span>
            <div className="fw-light">{registration.rutinSaatTeduh ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("question_25")}</span>
            <div className="fw-light">{registration.namaRohaniawan ?? "-"}</div>
          </ListGroupItem>

          <span>{translate("question_26")}</span>

          <ListGroupItem className="fw-bold">
            <span>{translate("consume_alcoholic_drinks")}</span>
            <div className="fw-light">
              {registration.pendapatMinumanBeralkohol ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("smoking")}</span>
            <div className="fw-light">
              {registration.pendapatMerokok ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("divorce_and_remarriage")}</span>
            <div className="fw-light">
              {registration.pendapatPerceraian ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("forced_abortion")}</span>
            <div className="fw-light">{registration.pendapatAborsi ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("homosexual")}</span>
            <div className="fw-light">
              {registration.pendapatHomoseksual ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("personal_debt")}</span>
            <div className="fw-light">
              {registration.pendapatHutangPribadi ?? "-"}
            </div>
          </ListGroupItem>

          <ListGroupItem className="fw-bold">
            <span>{translate("tatoo")}</span>
            <div className="fw-light">{registration.pendapatTatoo ?? "-"}</div>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </>
  );
}
