import React from "react";
import EventComponent from "../../components/Registration/RegistrationApproval/EventComponent";
import UserInformationComponent from "../../components/Registration/RegistrationApproval/UserInformationComponent";
import ConnectGroupComponent from "../../components/Registration/RegistrationApproval/ConnectGroupComponent";
import WaterBaptismComponent from "../../components/Registration/RegistrationApproval/WaterBaptismComponent";
import QuestionComponent from "../../components/Registration/RegistrationApproval/QuestionComponent";
import FormComponent from "../../components/Registration/RegistrationApproval/FormComponent";

export default function RegistrationApprovalComponent({
  translate,
  event,
  registration,
  event_id,
  person_id,
  leader_id,
}) {
  return (
    <>
      <EventComponent
        translate={translate}
        event={event}
        registration={registration}
      />

      <UserInformationComponent
        translate={translate}
        registration={registration}
      />

      <ConnectGroupComponent
        translate={translate}
        registration={registration}
      />

      {registration.baptismDate ? (
        <WaterBaptismComponent
          translate={translate}
          registration={registration}
        />
      ) : null}

      <QuestionComponent translate={translate} registration={registration} />

      <FormComponent
        translate={translate}
        registration={registration}
        event_id={event_id}
        person_id={person_id}
        leader_id={leader_id}
      />
    </>
  );
}
