import React from "react";
import { Card, CardBody } from "reactstrap";
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";

export default function RequirementComponent({ color, text, title }) {
  return (
    <Card color={color} outline className="my-3">
      <CardBody>
        <div className="row">
          <div className="col-auto m-auto">
            {color === "success" ? (
              <FaCheckCircle className="text-success display-1 m-auto" />
            ) : null}
            {color === "warning" ? (
              <FaInfoCircle className="text-warning display-1 m-auto" />
            ) : null}
            {color === "danger" ? (
              <FaTimesCircle className="text-danger display-1 m-auto" />
            ) : null}
          </div>
          <div className="col m-auto">
            <div className="text-muted">{title}</div>
            <div className="fw-bold">{text}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
