import Cookies from "universal-cookie";
import RegistrationStatusApi from "../api/RegistrationStatusApi";
import StepUtil from "../utils/StepUtil";

const cookies = new Cookies();

export default async function RegistrationStatusService({
  account,
  event,
  navigate,
}) {
  try {
    const person_id = account.account.mygms_id;
    const event_id = event.id;

    const registrationStatus = await RegistrationStatusApi({
      person_id: person_id,
      event_id: event_id,
    });

    const result = registrationStatus.data.result;

    cookies.set("registration_status", result, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    return result;
  } catch (error) {
    const result = error.response.data.result;
    const message = error.message;

    cookies.set("error", error, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_title", "Registration Status", {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    cookies.set("error_description", result ?? message, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    StepUtil("Home");

    navigate("/");

    throw new Error(error);
  }
}
