import React from "react";
import { Alert } from "reactstrap";

export default function AlertComponent({ translate }) {
  return (
    <Alert color="danger">
      <div className="fw-bold text-center mb-3">
        {translate("does_not_meet_terms_and_conditions")}
      </div>
      <p>
        {translate(
          "please_fulfill_and_complete_the_terms_and_conditions_so_you_can_take_part_in_the_servolution_before_the_servolution_is_closed"
        )}
      </p>
      <p>
        {translate(
          "we_re_sorry_you_havent_been_able_to_take_part_in_servolution_because_you_havent_met_the_terms_and_conditions_to_take_part_in_servolution"
        )}
      </p>
    </Alert>
  );
}
