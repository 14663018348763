import { Button, Card, CardBody, CardText } from "reactstrap";

import Swal from "sweetalert2";
import Cookies from "universal-cookie";

import { church_regional_id } from "../../utils/ConstantsUtil";

import EventCheckChurchService from "../../services/EventCheckChurchService";

const cookies = new Cookies();

export default function CheckChurchComponent({ translate, account }) {
  const changeChurch = async ({ church_id, church_name }) => {
    cookies.set("church_id", church_id, {
      expires: new Date(cookies.get("expired")),
      path: "/",
    });

    const event = await EventCheckChurchService({
      church_id: church_id,
    });

    if (event) {
      Swal.fire({
        title: translate("success"),
        html:
          translate("you_have_chose_to_be_registered_in") +
          "<br /><b>" +
          church_name +
          "</b>. <br />" +
          translate("please_proceed_to_continue_your_registration"),
        icon: "success",
        confirmButtonText: translate("continue"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } else {
      Swal.fire({
        title: translate("failed"),
        html: translate(
          "sorry_servolution_is_not_opened_yet_in_this_church"
        ),
        icon: "error",
        confirmButtonText: translate("close"),
        footer: translate("mawar_sharon_church"),
        allowOutsideClick: false,
      });
    }
  };

  if (account.account.church.church_regional_id !== church_regional_id) {
    return null;
  }

  if (
    account.account.church.church_id !==
    account.account.connect_groups[0].church_id
  ) {
    return (
      <Card className="mb-3">
        <CardBody>
          <CardText>
            {translate(
              "your_church_location_in_your_gms_church_app_is_different_with_your_connect_group_location_please_choose_where_you_want_to_register_the_servolution"
            )}
          </CardText>
          <Button
            color="primary"
            block
            onClick={() =>
              changeChurch({
                church_id: account.account.church.church_id,
                church_name: account.account.church.name,
              })
            }
          >
            {translate("register")} {translate("at")}{" "}
            {account.account.church.name}
          </Button>

          <Button
            color="info"
            block
            className="mt-3"
            onClick={() =>
              changeChurch({
                church_id: account.account.connect_groups[0].church_id,
                church_name: account.account.connect_groups[0].church_name,
              })
            }
          >
            {translate("register")} {translate("at")}{" "}
            {account.account.connect_groups[0].church_name}
          </Button>
        </CardBody>
      </Card>
    );
  }
}
