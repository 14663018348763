import React from "react";
import moment from "moment";
import { Alert, Button, Card, CardText, CardTitle, Col, Row } from "reactstrap";
import { church_regional_id } from "../../utils/ConstantsUtil";

export default function ScheduleComponent({
  account,
  translate,
  event,
  eventDetails,
  ministryId,
  divisionTypeId,
  divisionId,
  divisionName,
  step,
  type,
  onClick,
}) {
  let text = translate("schedule");

  if (event.servolutionTypeId !== 2) {
    if (step === 2) {
      text = translate("second_schedule");
    } else {
      text = translate("first_schedule");
    }
  }

  return (
    <>
      <Alert
        color={divisionTypeId === type.Audition ? "primary" : "info"}
        className="text-center mb-2"
        fade={false}
      >
        {divisionName}
      </Alert>

      <Alert color="light" className="text-center mb-2" fade={false}>
        {translate("choose")} {text}
      </Alert>

      {eventDetails
        .filter((eventDetail) =>
          ministryId ? eventDetail.ministry.id === ministryId : true
        )
        .map((eventDetail) =>
          eventDetail.ministry.divisions
            .filter((division) =>
              divisionId ? division.id === divisionId : true
            )
            .map((division) =>
              division.schedule.map((schedule, key) => (
                <div key={key} className="mb-2">
                  {key === 0 ? (
                    <Card
                      body
                      className="text-center"
                      color={division.ministryTypeId === 1 ? "primary" : "info"}
                      outline
                    >
                      <CardTitle tag="h6" className="fw-bold">
                        {division.name}
                      </CardTitle>

                      <CardText
                        className="text-start"
                        dangerouslySetInnerHTML={{
                          __html:
                            account.account.church.church_regional_id ===
                            church_regional_id
                              ? division.description
                              : schedule.description,
                        }}
                      ></CardText>
                    </Card>
                  ) : null}
                </div>
              ))
            )
        )}

      <Row>
        {eventDetails
          .filter((eventDetail) =>
            ministryId ? eventDetail.ministry.id === ministryId : true
          )
          .map((eventDetail) =>
            eventDetail.ministry.divisions
              .filter((division) =>
                divisionId ? division.id === divisionId : true
              )
              .map((division) =>
                division.schedule
                  // .sort((a, b) =>
                  //   a.auditionSchedule && b.auditionSchedule
                  //     ? a.auditionSchedule.localeCompare(b.auditionSchedule)
                  //     : a.interviewSchedule.localeCompare(b.interviewSchedule)
                  // )
                  .map((schedule, key) => (
                    <Col key={key} xs={12} className="my-2">
                      <Card
                        body
                        className="text-center h-100"
                        color={
                          division.ministryTypeId === 1 ? "primary" : "info"
                        }
                        outline
                      >
                        {division.ministryTypeId === 1 ? (
                          <div>
                            <h6 className="fw-bold">
                              {translate("audition_schedule")}
                            </h6>
                            <p>
                              {schedule.auditionSchedule &&
                              schedule.auditionSchedule !== "-"
                                ? moment(schedule.auditionSchedule).format(
                                    "LLLL"
                                  )
                                : translate("will_be_contacted_by_pic")}
                            </p>
                            <h6 className="fw-bold">
                              {translate("audition_room")}
                            </h6>
                            <p>
                              {schedule.auditionRoom &&
                              schedule.auditionRoom !== "-"
                                ? schedule.auditionRoom
                                : translate("will_be_contacted_by_pic")}
                            </p>
                          </div>
                        ) : null}

                        <div>
                          <h6 className="fw-bold">
                            {translate("interview_schedule")}
                          </h6>
                          <p>
                            {schedule.interviewSchedule &&
                            schedule.interviewSchedule !== "-"
                              ? moment(schedule.interviewSchedule).format(
                                  "LLLL"
                                )
                              : translate("will_be_contacted_by_pic")}
                          </p>
                          <h6 className="fw-bold">
                            {translate("interview_room")}
                          </h6>
                          <p>
                            {schedule.interviewRoom &&
                            schedule.interviewRoom !== "-"
                              ? schedule.interviewRoom
                              : translate("will_be_contacted_by_pic")}
                          </p>
                        </div>

                        <Button
                          className="w-100 text-white"
                          color={
                            division.ministryTypeId === 1 ? "primary" : "info"
                          }
                          size="sm"
                          onClick={() =>
                            onClick(
                              schedule.id,
                              schedule.auditionRoom ??
                                translate("will_be_contacted_by_pic"),
                              schedule.auditionSchedule
                                ? moment(schedule.auditionSchedule).format(
                                    "LLLL"
                                  )
                                : translate("will_be_contacted_by_pic"),
                              schedule.interviewRoom ??
                                translate("will_be_contacted_by_pic"),
                              schedule.interviewSchedule
                                ? moment(schedule.interviewSchedule).format(
                                    "LLLL"
                                  )
                                : translate("will_be_contacted_by_pic"),
                              schedule.description,
                              schedule.attachments
                            )
                          }
                        >
                          {translate("choose")} {translate("schedule")}
                        </Button>
                      </Card>
                    </Col>
                  ))
              )
          )}
      </Row>
    </>
  );
}
