import React from "react";
import { Alert, Button, Card, CardTitle, Col, Row } from "reactstrap";

export default function MinistryComponent({
  translate,
  event,
  eventDetails,
  search,
  searchType,
  step,
  type,
  onClick,
}) {
  let text = translate("ministry");

  if (event.servolutionTypeId !== 2) {
    if (step === 2) {
      text = translate("second_ministry");
    } else {
      text = translate("first_ministry");
    }
  }

  return (
    <>
      <Alert color="light" className="text-center mb-2" fade={false}>
        {translate("choose")} {text}
      </Alert>

      <Row className="mb-2">
        {eventDetails
          .filter((eventDetail) =>
            searchType
              ? eventDetail.ministry.ministryTypeId === searchType
              : true
          )
          .filter(
            (eventDetail) =>
              eventDetail.ministry.name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              eventDetail.ministry.divisions.filter((division) =>
                division.name.toLowerCase().includes(search.toLowerCase())
              ).length !== 0
          )
          .sort((a, b) => a.ministry.name.localeCompare(b.ministry.name))
          .map((eventDetail, key) => (
            <Col key={key} xs={6} className="my-2">
              <Card
                body
                className="text-center h-100"
                color={
                  eventDetail.ministry.ministryTypeId === type.Audition
                    ? "primary"
                    : "info"
                }
                outline
              >
                <CardTitle tag="h6">{eventDetail.ministry.name}</CardTitle>
                <div className="mt-auto">
                  <div className="my-2 text-muted small" tag="h6">
                    {eventDetail.ministry.divisions
                      .filter((division) =>
                        step === 2
                          ? division.ministryTypeId === type.Interview
                          : true
                      )
                      .filter((division) =>
                        step === 2
                          ? event.secondOptionMinistries.includes(division.id)
                          : true
                      )
                      .map((division) => division.name)
                      .sort((a, b) => a.localeCompare(b))
                      .join(", ")}
                  </div>
                  {eventDetail.ministry.divisions
                    .filter((division) =>
                      step === 2
                        ? division.ministryTypeId === type.Interview
                        : true
                    )
                    .filter((division) =>
                      step === 2
                        ? event.secondOptionMinistries.includes(division.id)
                        : true
                    )
                    .map((division) => division.name)
                    .sort((a, b) => a.localeCompare(b)).length ? (
                    <Button
                      className="w-100 text-white"
                      color={
                        eventDetail.ministry.ministryTypeId === type.Audition
                          ? "primary"
                          : "info"
                      }
                      size="sm"
                      onClick={() =>
                        onClick(
                          eventDetail.ministry.ministryTypeId,
                          eventDetail.ministry.id,
                          eventDetail.ministry.name
                        )
                      }
                    >
                      {translate("choose")} {translate("ministry")}
                    </Button>
                  ) : (
                    <Button
                      disabled={true}
                      className="w-100 text-white"
                      color={"secondary"}
                      size="sm"
                    >
                      {translate("Unavailable")}
                    </Button>
                  )}
                </div>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
}
