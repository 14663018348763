import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";

import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import StepUtil from "../utils/StepUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import CheckRegistrationStatusService from "../services/CheckRegistrationStatusService";
import CheckVolunteerService from "../services/CheckVolunteerService";
import EventService from "../services/EventService";

import ButtonComponent from "../components/ButtonComponent";
import QuestionFormComponent from "../components/Question/FormComponent";

const cookies = new Cookies();

export default function QuestionPage({ translate, setIsLoading, account }) {
  const titlePage = translate("question");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const [event, setEvent] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    if (cookies.get("step") !== "Question") {
      navigate("/ministry");
    }

    const fetchData = async () => {
      try {
        await CheckVolunteerService({ account: account, navigate: navigate });

        const event = await EventService({
          account: account,
          navigate: navigate,
        });
        setEvent(event);

        await CheckRegistrationStatusService({
          account: account,
          event: event,
          navigate: navigate,
        });
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();

    setIsLoading(false);
  }, [translate, setIsLoading, navigate, account]);

  return (
    <>
      <Card color="primary" outline>
        <CardHeader className="bg-primary text-white text-center">
          {translate("form")} {translate("servolution")}
        </CardHeader>
        <CardBody>
          <QuestionFormComponent
            translate={translate}
            account={account}
            event={event}
            navigate={navigate}
          />
        </CardBody>
        <CardFooter className="text-center">
          {translate("form")} {translate("servolution")}
        </CardFooter>
      </Card>

      <ButtonComponent
        link={"/ministry"}
        color={"light"}
        text={translate("back_to") + " " + translate("ministry")}
        icon={<FaArrowLeft className="me-2" />}
        sortBy={"right"}
        onClick={() => StepUtil("Ministry")}
      />
    </>
  );
}
